import PropTypes from 'prop-types'
import gql from 'graphql-tag'
import { Query } from '@apollo/client/react/components'
import LinearProgress from '@material-ui/core/LinearProgress'
import NoSsr from '@material-ui/core/NoSsr'
import defaults from '@config/defaults'
import Error from '@components/error'
import ErrorBoundary from '@components/error/boundary'
import IndividualLeaderboard from '@components/leaderboard-team/individual-leaderboard'
import Leaderboard from './leaderboard'
import { LeaderboardProvider } from './leaderboard.provider'
import MyLeaderboard from './leaderboard.saved'

const QUERY = gql`
query Leaderboard($id: String!, $feedId: String!, $limit: Int) {
  tournament(tournamentId: $id, feedId: $feedId) {
		tournamentId
    name
    feedId
    currentRound
    currentRoundState
    rounds
    updatedAt
    cutPlaces
    cutRound
    cutScore
    players(limit: $limit) {
      sortIndex
      streamUrl
      player {
        firstName
        lastName
        playerId,
        country
      }
      currentRound
      currentPosition
      currentRelativePar
      thru
      teeTime
      tournamentRelativePar
      tournamentStreak
      rounds {
        round
        stroke
        thru
        relativePar
      }
    }
  }
}
`

export default function LeaderboardWithData(props) {
  const { leaderboardPollInterval, fullPage, nested } = props

  let pollInterval = process.browser ? defaults.leaderboardPollInterval : null
  pollInterval = process.browser && leaderboardPollInterval
    ? leaderboardPollInterval * 60 * 1000 : pollInterval
  const fetchPolicy = process.browser ? 'cache-and-network' : 'cache-first'
  return (
    <NoSsr>
      <Query
        query={QUERY}
        variables={props}
        pollInterval={pollInterval}
        fetchPolicy={fetchPolicy}
        context={{ clientName: 'pga-datafeed' }}
      >
        {({
          error, data, loading,
        }) => {
          const response = data && data.tournament

          if (loading && !response) return <LinearProgress />
          if (error) return <Error error={error} />

          return response ? (
            <ErrorBoundary>
              <LeaderboardProvider>
                {fullPage && <MyLeaderboard {...props} {...response} />}
                {nested && !!response.players.length && <IndividualLeaderboard {...props} {...response} />}
                {!nested && <Leaderboard {...props} {...response} />}
              </LeaderboardProvider>
            </ErrorBoundary>
          ) : <div />
        }}
      </Query>
    </NoSsr>
  )
}

LeaderboardWithData.propTypes = {
  fullPage: PropTypes.bool,
  leaderboardPollInterval: PropTypes.number,
  nested: PropTypes.bool,
}
