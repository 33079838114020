import React, { useEffect, useRef, useState } from 'react'
import Card from '@material-ui/core/Card'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import PropTypes from 'prop-types'
import ButtonCta from '@components/atoms/button-cta'
import Image from '@components/atoms/image'
import {
  IconFlag,
  IconMarker,
  IconPrice,
} from '@components/atoms/icons'
import useStyles from './mobile-showcase-event-banner.styles'
import Link from '@components/link/index'
import useOnScreen from '@hooks/useOnScreen'
import { CardIcon } from '@components/group-event-card-large/group-event-card-large.styles'
import { formattedEventPriceLabel } from '@components/group-event-card/group-event-card'
import { gtmEvent } from '@lib/gtm-utils'
import { DateTime } from 'luxon'

const MobileShowcaseEventBanner = (props) => {
  const {
    title,
    coach_facility_name: course,
    coach_facility_full_address: location,
    start_date_time: startDate,
    total_price_in_cents: totalPriceInCents,
    payment_recurrence_schedule: paymentRecurrenceSchedule,
    additional_fees: additionalFees,
    price_in_cents: priceInCents,
    eventType,
    coach_id: coachId,
    objectID,
    slug
  } = props.data

  const bannerAdRef = useRef(null)
  const isCardVisible = useOnScreen(bannerAdRef)
  const [bannerHasBeenViewed, setBannerHasBeenViewed] = useState(false)

  const eventTypeChoices = {
    'group-event': {
      logoUrl: '/images/logo-blue.png',
      tagline: 'Want to Play like a Pro?',
      registrationLink: `${process.env.PGA_COM_URL}/things-to-do/events/${slug}`,
      backgroundImageUrl: 'https://images.ctfassets.net/56u5qdsjym8c/4PtMahTAjmw7iYKpNGUzzQ/fe039179b4f12d263a80338b60baef9a/Finau-Bunker.png'
    },
    'jrl-program': {
      logoUrl: '/images/jr-league-logo.svg',
      tagline: 'Friends, Fairways & Fun',
      registrationLink: `${process.env.JRL_MARKETING_SITE_URL}/junior-league-events/${objectID}`,
      backgroundImageUrl: 'https://images.ctfassets.net/56u5qdsjym8c/1yoePOUAugYt2Za7y7Zmca/394ca263cad1c11e1344efb13ed2e0e3/JRL_programs_bg.png'
    },
    'fg-program': {
      logoUrl: '/images/family-golf-logo.svg',
      tagline: 'Fairways of Family Fun',
      registrationLink: `${process.env.FG_MARKETING_SITE_URL}/events/${objectID.split('-')[1]}`,
      backgroundImageUrl: 'https://images.ctfassets.net/56u5qdsjym8c/5SWGO8vvLshekEP1mGo826/0345e17afa5d282c0bafb50174b06bf5/FG_programs_bg.png'
    },
    'hope-event': {
      logoUrl: '/images/pga-hope-logo.svg',
      tagline: 'Drive with Honor',
      registrationLink: `${process.env.PGA_COM_URL}/things-to-do/events/${slug}`,
      backgroundImageUrl: 'https://images.ctfassets.net/56u5qdsjym8c/6kJCbIwEgv3zHxS6Tu8RZA/9bfd7fd244c3006ad414918213d5be71/Costs__2_.jpg'
    }
  }

  useEffect(() => {
    if (isCardVisible && !bannerHasBeenViewed) {
      gtmEvent({
        event: 'leaderboard-event-ad-impression',
        attributes: {
          ad_type: eventType,
          start_date: DateTime.fromISO(startDate).toFormat('yyyy-LL-dd'),
          price: priceInCents / 100,
          title: title,
        },
      })
      // Remove the event below in the future.
      gtmEvent({
        event: 'leaderboard_ad_impression',
        attributes: {
          bannerAdAction: 'impression',
          eventId: objectID,
          bannerAdType: eventType,
          coachId: coachId,
        }
      })
      setBannerHasBeenViewed(true)
    }
  }, [isCardVisible])

  const handleBannerClick = () => {
    gtmEvent({
      event: 'leaderboard-event-ad-click',
      attributes: {
        ad_type: eventType,
        start_date: DateTime.fromISO(startDate).toFormat('yyyy-LL-dd'),
        price: priceInCents / 100,
        title: title,
      },
    })
    // Remove the event below in the future.
    gtmEvent({
      event: 'leaderboard_ad_impression',
      attributes: {
        bannerAdAction: 'click',
        eventId: objectID,
        bannerAdType: eventType,
        coachId: coachId,
      }
    })
  }

  const styles = useStyles()
  const logoUrl = eventTypeChoices[eventType].logoUrl
  const backgroundImageUrl = eventTypeChoices[eventType].backgroundImageUrl
  const registrationLink = eventTypeChoices[eventType].registrationLink
  const anchorProps = {
    'data-gtm-leaderboard-event-id': objectID,
    'data-gtm-leaderboard-event-type': eventType,
    'data-gtm-leaderboard-coach-id': coachId,
  }

  const start = new Date(startDate)

  const startMonth = start.toLocaleDateString('en-us', { month: 'short' })
  const startDay = start.toLocaleDateString('en-us', { day: 'numeric' })
  const startYear = start.toLocaleDateString('en-us', { year: 'numeric' })

  const dateToDisplay = `${startDay} ${startMonth}, ${startYear}`

  return (
    <Link externalLink={registrationLink} anchorProps={anchorProps} className={styles.root} title={title}>
      <Card className={styles.root} onClick={() => handleBannerClick()} ref={bannerAdRef}>
        <div className={styles.imageContainer}>
          {backgroundImageUrl && <Image className={styles.image} src={`${backgroundImageUrl}?h=200&w=600&fit=fill&fl=progressive&q=80`} alt={`View page for ${title}`} />}
          <CardIcon
            className={styles.logo}
            background={`${logoUrl}?fm=jpg&h=200&w=200&fit=fill&fl=progressive&q=80`}
            alt={title}
          />
        </div>
        <div className={styles.contentContainer}>
          <Box className={styles.headlineWrapper}>
            <Typography variant="h6" className={styles.headline}>{title}</Typography>
            <Box className={styles.dateRange}>
              {dateToDisplay}
            </Box>
          </Box>
          <Box className={styles.locationWrapper}>
            {course && (
              <div className={styles.field}><IconFlag className={styles.icon} viewBox="6 0 24 24" />
                <Typography variant="caption" className={styles.fieldText}>{course}</Typography>
              </div>
            )}
            {location && (
              <div className={styles.field}><IconMarker className={styles.icon} viewBox="6 0 24 24" />
                <Typography variant="caption" className={styles.fieldText}>{location}</Typography>
              </div>
            )}
            <div className={styles.priceField}><IconPrice className={styles.icon} viewBox="6 0 24 24" />
              <Typography variant="caption" className={styles.fieldText}>
                {formattedEventPriceLabel({
                  priceInCents: priceInCents,
                  paymentRecurrenceSchedule: paymentRecurrenceSchedule,
                  additionalFees: additionalFees,
                  totalPriceInCents: totalPriceInCents,
                  isJRL: eventTypeChoices[eventType] === 'jrl-program',
                  isFG: eventTypeChoices[eventType] === 'fg-program',
                })}
              </Typography>
            </div>
          </Box>
        </div>
        <ButtonCta
          variant="text"
          arrow
          className={styles.ctaButton}
          externalLink={registrationLink}
          title={title}
          dataAttrs={anchorProps}
        >
          View Event
        </ButtonCta>
      </Card>
    </Link>
  )
}

MobileShowcaseEventBanner.propTypes = {
  coach: PropTypes.object,
  title: PropTypes.string,
  image: PropTypes.shape({
    url: PropTypes.string,
  }),
  logo: PropTypes.shape({
    url: PropTypes.string,
  }),
  slug: PropTypes.string,
  startDate: PropTypes.instanceOf(Date),
  endDate: PropTypes.instanceOf(Date),
  location: PropTypes.string,
  course: PropTypes.string,
  heroImage: PropTypes.shape({
    url: PropTypes.string,
  }),
}

export default MobileShowcaseEventBanner
