import React from 'react'

const TrophyIcon = props => (
  <svg
    width={20}
    height={20}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx={10} cy={10} r={10} fill="#AB9157" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.434 7.082c.162 0 .283.117.324.254.02.078.425 2.031-.85 3.828-.85 1.211-2.226 2.031-4.107 2.48-.284.079-.486.313-.486.606v.957h1.942c.183 0 .304.156.304.313a.31.31 0 0 1-.304.312H7.745c-.182 0-.344-.137-.344-.313 0-.156.162-.312.344-.312h1.943l-.02-.957a.615.615 0 0 0-.486-.605c-1.882-.45-3.258-1.27-4.087-2.48-1.275-1.798-.87-3.75-.85-3.83a.343.343 0 0 1 .323-.253h2.206V6.75c0-.508.445-.918.971-.918h4.512c.527 0 .972.41.972.918v.332h2.205Zm-7.689-.625a.314.314 0 0 0-.324.313c-.022.86.016 2.362.452 3.722h4.257c.431-1.36.464-2.862.43-3.722.021-.176-.12-.313-.303-.313H7.745Zm4.15 4.666H8.111c.388.883.986 1.643 1.9 2.033.907-.39 1.5-1.15 1.885-2.033ZM4.853 7.707c-.061.547-.081 1.895.769 3.125.607.86 1.558 1.504 2.792 1.934-1.234-1.368-1.578-3.536-1.639-5.059H4.852Zm6.738 5.059c1.234-.43 2.185-1.075 2.792-1.934.85-1.23.83-2.578.769-3.125h-1.922c-.061 1.523-.405 3.691-1.64 5.059Z"
      fill="#fff"
    />
  </svg>
)

export default TrophyIcon
