import nc from 'namecase'

export const isTextSingleCased = (text) => {
  if (text === undefined) { return false }
  if (text === null) { return false }
  if (typeof text !== 'string') { return false }
  if (text.trim() === '') { return false }

  return text === text.toLowerCase() || text === text.toUpperCase()
}

export const formatNameForDisplay = (name) => {
  if (!isTextSingleCased(name)) { return name }

  return nc(name)
}
