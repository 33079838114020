import Typography from '@material-ui/core/Typography'
import {
  Bogey,
  DoubleBogey,
  Birdey,
  Eagle,
  Albatross,
  LegendContainer,
  Legend,
} from './scorecard.styles'

const ScorecardScoring = () => {
  return (
    <LegendContainer>
      <Legend>
        <Albatross mini="true" /> <Typography variant="caption">Ace/Albatross</Typography>
      </Legend>
      <Legend>
        <Eagle mini="true" /> <Typography variant="caption">Eagle</Typography>
      </Legend>
      <Legend>
        <Birdey mini="true" /> <Typography variant="caption">Birdie</Typography>
      </Legend>
      <Legend>
        <Bogey mini="true" /> <Typography variant="caption">Bogey</Typography>
      </Legend>
      <Legend>
        <DoubleBogey mini="true" /> <Typography variant="caption">Double Bogey or worse</Typography>
      </Legend>
    </LegendContainer>
  )
}


export default ScorecardScoring
