import gql from 'graphql-tag'
import { Query } from '@apollo/client/react/components'
import LinearProgress from '@material-ui/core/LinearProgress'
import Error from '@components/error'
import defaults from '@config/defaults'
import ErrorBoundary from '@components/error/boundary'
import ScorecardContainer from './scorecard.container'

const QUERY = gql`
query Scorecard($tournamentId: String!, $playerId: String!, $feedId: String!) {
  scorecard(tournamentId: $tournamentId, playerId: $playerId, feedId: $feedId) {
    round
    front
    back
    relativePar
    stroke
    thru
    courseName
    holes {
      hole
      par
      score
      rRelativePar
      tRelativePar
    }
  }
}
`

export default function ScorecardWithData(props) {
  const pollInterval = process.browser ? defaults.leaderboardPollInterval : null
  const fetchPolicy = process.browser ? 'cache-and-network' : 'cache-first'
  return (
    <Query
      query={QUERY}
      variables={props}
      pollInterval={pollInterval}
      fetchPolicy={fetchPolicy}
      context={{ clientName: 'pga-datafeed' }}
    >
      {({
        loading, error, data,
      }) => {
        const response = data && data.scorecard
        if (error) return <Error error={error} />
        if (loading && !response) return <LinearProgress color="secondary" />
        return (
          <ErrorBoundary>
            <ScorecardContainer {...props} rounds={response} />
          </ErrorBoundary>
        )
      }}
    </Query>
  )
}

ScorecardWithData.propTypes = {}
