import Box from '@material-ui/core/Box'
import PropTypes from 'prop-types'
import { diffInMinutes, getDeltaString } from '@utils/dateUtils'
import useStyles from './leaderboard.style-hook'

export default function Disclaimer(props) {
  const {
    updatedAt,
  } = props

  const updated = new Date(updatedAt * 1)
  const minutesAgo = diffInMinutes(updated)

  const styles = useStyles()

  return (
    <Box color="grey.700" data-cy="leaderboard-disclaimer" className={styles.disclaimer}>
      {updatedAt && `Last updated ${getDeltaString(minutesAgo, updated)}. `}
      All tee times are local.
    </Box>
  )
}

Disclaimer.propTypes = {
  updatedAt: PropTypes.string,
}
