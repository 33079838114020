/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import algoliasearch from 'algoliasearch'
import NoSsr from '@material-ui/core/NoSsr'
import { Container, Box } from '@material-ui/core'
import ErrorBoundary from '@components/error/boundary'
import LeaderboardCutLine from '@components/leaderboard-cut-line'
import LeaderboardHead from '@components/leaderboard/leaderboard-head'
import LeaderboardItem from '@components/leaderboard/leaderboard-item'
import useStyles from '@components/leaderboard/leaderboard.style-hook'
import CoachAdvertisement from '@components/leaderboard/CoachAdvertisement'
import { getClosestCoachByIp } from '@components/leaderboard/utils'
import LeaderboardOuterItem from '@components/leaderboard-scramble/leaderboard-outer-item'

const client = algoliasearch(process.env.ALGOLIA_APP_ID, process.env.ALGOLIA_API_KEY)
const algoliaIndex = client.initIndex(process.env.ALGOLIA_COACH_INDEX_NAME)

const IndividualLeaderboard = React.memo((props) => {
  const {
    players,
    id,
    feedId,
    name: eventName,
    enableScorecard,
    bordered,
    currentRound,
    currentRoundState,
    totalRounds,
    rounds = 4,
    style,
    enableStatus,
    liveStreamUrl,
    nativeAdTitle,
    nativeAdDescription,
    nativeAdCta,
    nativeAdActive,
    cutPlaces,
    cutRound,
    cutScore,
    leaderboardPlaces,
    nested,
    cutLineText,
  } = props

  const tournamentRounds = totalRounds || rounds
  const isTournamentComplete = Number(currentRound) === tournamentRounds && currentRoundState === 'Completed'
  const playoffCompleted = isTournamentComplete && (players[0].tournamentStreak === players[1].tournamentStreak) && Number(players[0].currentPosition) === 1

  const styles = useStyles({ nested })
  const [nearestCoach, setNearestCoach] = useState(null)
  const [loadingCoach, setLoadingCoach] = useState(false)
  useEffect(() => {
    const getCoach = async () => {
      setLoadingCoach(true)
      const coach = await getClosestCoachByIp(algoliaIndex)
      setLoadingCoach(false)
      if (coach) {
        setNearestCoach(coach)
      }
    }
    getCoach()
  }, [])

  const showPlacesDict = {
    3: 3,
    5: 5,
    10: 10,
    20: 20,
    'Cut only': cutPlaces,
    'All players': null,
    null: 5, // default places if no leaderboardPlaces value provided by Contentful
  }

  const limitValue = showPlacesDict[leaderboardPlaces]
  const showFavorite = false
  const filteredPlayers = (players || []).filter(player => player != null && player.player != null && player.sortIndex != null)
  const orderedPlayers = filteredPlayers.sort((a, b) => a.sortIndex - b.sortIndex)
  const show = limitValue ? orderedPlayers.slice(0, limitValue) : orderedPlayers
  const winner = `${orderedPlayers[0].player.firstName} ${orderedPlayers[0].player.lastName}`

  const [showLeaderboard, toggleLeaderboard] = useState(false)

  function handleToggle() {
    toggleLeaderboard(!showLeaderboard)
  }

  const paddingY = [3, 2, 1]

  return (
    <Box py={paddingY} style={style}>
      <Container>
        <LeaderboardOuterItem
          matchName="Individual"
          isTournamentComplete={isTournamentComplete}
          eventStage={null}
          winner={winner}
          handleToggle={handleToggle}
          showLeaderboard={showLeaderboard}
        />
      </Container>
      {showLeaderboard && (
        <Container className={styles.wrapper} role="table">
          <LeaderboardHead rounds={Number(currentRound)} showFavorite={showFavorite} nested={nested} />
          {show.map((player, i) => (
            <ErrorBoundary key={player.player.playerId}>
              {i === 5 && nativeAdActive && nearestCoach
                ? (
                  <NoSsr>
                    <CoachAdvertisement
                      isLoading={loadingCoach}
                      coach={nearestCoach}
                      nativeAdTitle={nativeAdTitle}
                      nativeAdDescription={nativeAdDescription}
                      nativeAdCta={nativeAdCta}
                      leaderboardEvent={{
                        eventName,
                        tourName: feedId
                      }}
                    />
                  </NoSsr>
                ) : null}
              {i === cutPlaces && currentRound >= cutRound ? (
                <LeaderboardCutLine
                  cutScore={cutScore}
                  cutRound={cutRound}
                  currentRound={currentRound}
                  currentRoundState={currentRoundState}
                  cutLineText={cutLineText}
                />
              ) : null}
              <LeaderboardItem
                bordered={bordered}
                {...player}
                tournamentId={id}
                feedId={feedId}
                enableScorecard={enableScorecard}
                enableStatus={enableStatus}
                rounds={player.rounds}
                liveStreamUrl={liveStreamUrl}
                showFavorite={showFavorite}
                winner={playoffCompleted && i === 0}
                tournamentCurrentRound={Number(currentRound)}
                eventName={eventName}
              />
            </ErrorBoundary>
          ))}
        </Container>
      )}
    </Box>
  )
})

IndividualLeaderboard.propTypes = {
  bordered: PropTypes.bool,
  enableScorecard: PropTypes.bool,
  feedId: PropTypes.string,
  id: PropTypes.string,
  players: PropTypes.array,
  rounds: PropTypes.number,
  style: PropTypes.object,
  liveStreamUrl: PropTypes.string,
  enableStatus: PropTypes.bool,
  nativeAdTitle: PropTypes.string,
  nativeAdDescription: PropTypes.string,
  nativeAdCta: PropTypes.string,
  nativeAdActive: PropTypes.bool,
  currentRound: PropTypes.string,
  currentRoundState: PropTypes.string,
  cutPlaces: PropTypes.number,
  cutRound: PropTypes.number,
  cutScore: PropTypes.number,
  leaderboardPlaces: PropTypes.string,
  totalRounds: PropTypes.number,
  nested: PropTypes.bool,
  cutLineText: PropTypes.string,
}

IndividualLeaderboard.defaultProps = {
  players: [],
}

export default IndividualLeaderboard
