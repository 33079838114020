import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import Scorecard from './scorecard'

export const addFields = (array = [], val) => array
  .map(item => (parseInt(item[val], 10) || 0))
  .reduce((prev, next) => prev + next, 0)

function isTurnComplete(holes) {
  let finished = true
  holes.forEach((hole) => {
    const score = Number(hole.score)
    if (score < 1) finished = false
  })
  return finished
}
/**
 * Given the number of available rounds, and the current round (which is one-indexed),
 * return the index of the (zero-indexed) rounds array to show.
 */
export function getRoundToShow(currentRound, numRounds) {
  // 'currentRound' is one-indexed
  let roundToShow = currentRound - 1
  // don't go out of bounds of the rounds array
  if (roundToShow >= numRounds) roundToShow = Math.max(0, numRounds - 1)
  return roundToShow
}

const ScorecardContainer = (props) => {
  const {
    currentRound,
    rounds,
    handleToggle,
    thru,
    name,
  } = props
  // FIXME: If there are now holes, we can't display a scorecard
  if (!rounds || !rounds[0] || !rounds[0].holes) return <div>No Scorecard Available</div>

  const startedBack9 = thru && thru.includes('*')
  const fixThru = (thru.indexOf('F') > -1) ? 18 : Number(thru && thru.replace('*', ''))
  const showBack9 = startedBack9 && (fixThru < 9)

  const [round, setRound] = useState(getRoundToShow(
    currentRound > 4 ? 4 : currentRound, rounds.length,
  ))
  const [turn, setTurn] = useState(showBack9 ? 'back' : 'front')

  const card = rounds && rounds[round] && rounds[round].holes
  const isMobile = useMediaQuery(useTheme().breakpoints.down('sm'))
  const sortedRounds = card && [...card].sort(({ hole: a }, { hole: b }) => a - b)

  const front = sortedRounds && sortedRounds.slice(0, 9)
  const frontCalculated = isTurnComplete(front) ? addFields(front, 'score') : null

  const back = sortedRounds && sortedRounds.slice(9, 18)
  const backCalculated = isTurnComplete(back) ? addFields(back, 'score') : null

  const inPar = addFields(back, 'par')
  const outPar = addFields(front, 'par')
  // const inScore = rounds && rounds[round].back ? rounds[round].back : backCalculated
  // const outScore = rounds && rounds[round].front ? rounds[round].front : frontCalculated
  const totalScore = (backCalculated && frontCalculated) ? backCalculated + frontCalculated : '-'

  const handleRoundChange = (event, newValue) => {
    if (window && window.dataLayer) {
      window.dataLayer.push({
        playerName: name,
        round: newValue + 1,
      })
    }
    setRound(newValue)
  }

  const handleTurnChange = (event, newValue) => {
    setTurn(newValue)
  }

  const limitRoundsToCurrent = typeof (currentRound) === 'number'

  return (
    <div data-cy="scorecards">
      <Scorecard
        {...props}
        currentRound={currentRound}
        numRounds={(limitRoundsToCurrent && currentRound) || rounds.length}
        round={round}
        handleRoundChange={handleRoundChange}
        turn={turn}
        handleTurnChange={handleTurnChange}
        card={card}
        front={front}
        back={back}
        isMobile={isMobile}
        inPar={inPar}
        outPar={outPar}
        inScore={backCalculated}
        outScore={frontCalculated}
        thru={rounds[round].thru}
        totalScore={totalScore}
        handleToggle={handleToggle}
      />
    </div>
  )
}

ScorecardContainer.propTypes = {
  currentRound: PropTypes.number,
  headline: PropTypes.string,
  rounds: PropTypes.array,
  tournamentId: PropTypes.string,
  playerId: PropTypes.string,
  handleToggle: PropTypes.func,
  thru: PropTypes.string,
  name: PropTypes.string,
}

ScorecardContainer.defaultProps = {}

export default ScorecardContainer
