import PropTypes from 'prop-types'
import Box from '@material-ui/core/Box'
import Tabs from '@material-ui/core/Tabs'
import Typography from '@material-ui/core/Typography'
import ScorecardScoring from '@components/scorecard/scorecard-scoring'
import ScoreCell from '@components/scorecard/score-cell'
import Close from '@material-ui/icons/Close'
import { CloseButton } from '@components/leaderboard/leaderboard.styles'
import {
  ScorecardRoot,
  TurnBox,
  ColBox,
  FirstCol,
  CellBox,
  ScoreCellBox,
  FirstCell,
  RoundTab,
  RoundTabs,
  HalfTab,
  useStyles,
} from './scorecard.styles'

export const checkTurn = (turn, thisTurn) => {
  return turn === thisTurn ? 'flex' : 'none'
}

const Scorecard = (props) => {
  const {
    rounds,
    round,
    handleRoundChange,
    turn,
    handleTurnChange,
    front,
    back,
    isMobile,
    inPar,
    inScore,
    numRounds,
    outPar,
    outScore,
    totalScore,
    name,
    enableStatus,
    handleToggle,
    handleTrackingEvent,
  } = props

  const styles = useStyles()

  return (
    <ScorecardRoot>
      <div className={styles.tabsCloseRow}>
        <CloseButton className={styles.closeButton} onClick={handleToggle}><Close /></CloseButton>
        <RoundTabs value={round} onChange={handleRoundChange}>
          <RoundTab
            label={isMobile ? 'RD 1' : 'Round 1'}
            data-gtm-player-name={name}
            data-gtm-round={1}
            onClick={() => handleTrackingEvent('click_round_1')}
          />
          {numRounds > 1 && (
            <RoundTab
              label={isMobile ? 'RD 2' : 'Round 2'}
              data-gtm-player-name={name}
              data-gtm-round={2}
              onClick={() => handleTrackingEvent('click_round_2')}
            />
          )}
          {numRounds > 2 && (
            <RoundTab
              label={isMobile ? 'RD 3' : 'Round 3'}
              data-gtm-player-name={name}
              data-gtm-round={3}
              onClick={() => handleTrackingEvent('click_round_3')}
            />
          )}
          {numRounds > 3 && (
            <RoundTab
              label={isMobile ? 'RD 4' : 'Round 4'}
              data-gtm-player-name={name}
              data-gtm-round={4}
              onClick={() => handleTrackingEvent('click_round_4')}
            />
          )}
        </RoundTabs>
      </div>

      {isMobile && (
        <Tabs value={turn} onChange={handleTurnChange} indicatorColor="primary" variant="fullWidth">
          <HalfTab value="front" label="Front 9" />
          <HalfTab value="back" label="Back 9" />
        </Tabs>
      )}

      <Box display="flex" flexDirection="column">
        {rounds[round].courseName && <Typography variant="h6" className={styles.courseName}>{rounds[round].courseName}</Typography>}
        <Box display="flex">
          <TurnBox
            style={{ display: isMobile ? checkTurn(turn, 'front') : 'flex' }}
          >
            <FirstCol>
              <FirstCell><div>Hole</div></FirstCell>
              <FirstCell><div>Par</div></FirstCell>
              <FirstCell><div>Score</div></FirstCell>
              {enableStatus && <FirstCell><div>Status</div></FirstCell>}
            </FirstCol>

            {front.map(cell => <ScoreCell {...cell} enableStatus={enableStatus} key={`${cell.round}-${cell.hole}`} />)}

            <ColBox>
              <CellBox>Out</CellBox>
              <CellBox>{outPar}</CellBox>
              <CellBox>{outScore || '-'}</CellBox>
              {enableStatus && <ScoreCellBox>{outScore ? front[8].rRelativePar : '-'}</ScoreCellBox>}
            </ColBox>
          </TurnBox>

          <TurnBox
            style={{ display: isMobile ? checkTurn(turn, 'back') : 'flex' }}
          >
            {(turn === 'back' && isMobile) && (
              <FirstCol>
                <FirstCell><div>Hole</div></FirstCell>
                <FirstCell><div>Par</div></FirstCell>
                <FirstCell><div>Score</div></FirstCell>
                {enableStatus && <FirstCell><div>Status</div></FirstCell>}
              </FirstCol>
            )}
            {back.map(cell => <ScoreCell {...cell} enableStatus={enableStatus} key={`${cell.round}-${cell.hole}`} />)}
            <ColBox>
              <CellBox>In</CellBox>
              <CellBox>{inPar}</CellBox>
              <ScoreCellBox>{inScore || '-'}</ScoreCellBox>
              {enableStatus && <ScoreCellBox>{inScore ? back[8].rRelativePar : '-'}</ScoreCellBox>}
            </ColBox>
            <ColBox>
              <CellBox><span className={styles.lastCell}>Tot</span></CellBox>
              <CellBox><span className={styles.lastCell} data-cy="total">{outPar + inPar}</span></CellBox>
              <ScoreCellBox>
                <span className={styles.lastCell} data-cy="total-score">{totalScore}</span>
              </ScoreCellBox>
              {enableStatus && (
                <ScoreCellBox>
                  <span className={styles.lastCell}>
                    {totalScore !== '-' ? rounds[round].relativePar : '-'}
                  </span>
                </ScoreCellBox>
              )}
            </ColBox>
          </TurnBox>
        </Box>
      </Box>

      <ScorecardScoring />

    </ScorecardRoot>
  )
}

Scorecard.propTypes = {
  numRounds: PropTypes.number,
  round: PropTypes.number,
  turn: PropTypes.string,
  name: PropTypes.string,
  handleRoundChange: PropTypes.func,
  handleTurnChange: PropTypes.func,
  front: PropTypes.arrayOf(PropTypes.shape),
  back: PropTypes.arrayOf(PropTypes.shape),
  rounds: PropTypes.arrayOf(PropTypes.shape),
  isMobile: PropTypes.bool,
  inPar: PropTypes.number,
  inScore: PropTypes.number,
  outPar: PropTypes.number,
  outScore: PropTypes.number,
  totalScore: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  enableStatus: PropTypes.bool,
  handleToggle: PropTypes.func,
  handleTrackingEvent: PropTypes.func,
}

Scorecard.defaultProps = {
  front: [],
  back: [],
}

export default Scorecard
