export const leaderboardFragment = `
tourName
heroImage {
  url
}
headline
course
location
startDate
endDate
totalRounds
location
metaDescription
slug
logo {
  url
}
extraLogo {
  url
}
tournamentId
feedId
ticketCta
ticketLink
showTicketLink
watchLiveUrl
liveStreamUrl
nativeAdTitle
nativeAdDescription
nativeAdCta
nativeAdActive
teamOf20PlayerIds
showTeam20Cutline
cutLineText
teamOf20CutLineText
cutLineTextOverride
cutPlacesOverride
featuredButton
showExternalAd
format
fullCoverageUrl
findCoachTitle
findCoachSubtitle
findCoachCta
leaderboardPollInterval
leaderboardPlaces
eventTournamentIds
hideThru
articles {
   articlesCollection {
    items {
      headline
      summary
      slug
      image {
        url
      }
    }
  }
 }
`
