import { styled, makeStyles } from '@material-ui/core/styles'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import { eyebrowFont } from '../../config/mui-theme'

export const ScorecardRoot = styled('div')(({ theme }) => ({
  background: 'inherit',
  color: theme.palette.secondary.main,
  flexBasis: '100%',
}))

export const TurnBox = styled('div')(({ theme }) => ({
  textTransform: 'uppercase',
  width: '100%',
  [theme.breakpoints.up('md')]: {
    width: '50%',
  },
}))

export const CellBox = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderTop: `1px solid ${theme.palette.grey.light}`,
  marginTop: '4px',
  height: 40,
  fontWeight: 700,
  fontFamily: eyebrowFont,
  letterSpacing: '1.5px',
  color: theme.palette.common.black,

  '&:first-child': {
    marginTop: 0,
  },
  '&:nth-child(n+3)': {
    height: 52,
  },
  '&:last-child': {
    borderBottom: `1px solid ${theme.palette.grey.light}`,
  },
}))

export const ScoreCellBox = styled(CellBox)(() => ({
  position: 'relative',
}))

export const ColBox = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: theme.spacing(5),
  flexGrow: 1,
}))

export const FirstCol = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    width: theme.spacing(12),
  },
  [theme.breakpoints.down('sm')]: {
    width: theme.spacing(2),
  },
}))

export const FirstCell = styled(CellBox)(({ theme }) => ({
  position: 'relative',
  justifyContent: 'flex-start',
  textAlign: 'left',
  ...theme.typography.caption,
  color: theme.palette.common.black,
  fontWeight: 700,
  fontFamily: eyebrowFont,
  letterSpacing: '1.5px',
  borderLeft: `4px solid ${theme.palette.secondary.light}`,
  '&:nth-child(3)': {
    borderLeft: `4px solid ${theme.palette.primary.main}`,
  },
  [theme.breakpoints.up('md')]: {
    paddingLeft: theme.spacing(2),
    '& div': {
      borderRight: `1px solid ${theme.palette.grey.light}`,
      width: '100%',
      padding: '2px 0',
    },
  },
  [theme.breakpoints.down('sm')]: {
    '& div': {
      flexShrink: 0,
      transform: 'rotate(-90deg) translate(-30px, 0)',
      transformOrigin: 'top left',
      textAlign: 'center',
      width: 40,
      fontSize: '0.625rem',
    },
  },
}))

export const RoundTabs = styled(Tabs)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  marginBottom: theme.spacing(1),
  '& .MuiTabs-indicator': {
    display: 'none',
  },
  [theme.breakpoints.down('xs')]: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
}))

export const RoundTab = styled(Tab)(({ theme }) => ({
  ...theme.typography.formLabels,
  fontWeight: 700,
  minHeight: 0,
  minWidth: 0,
  height: '29px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '0px 10px',
  marginRight: '0.625rem',
  borderRadius: '6px',
  backgroundColor: 'none',
  color: theme.palette.grey.dark,
  textDecoration: 'none',
  textTransform: 'capitalize',
  opacity: 1,
  outline: 'none !important',
  '&.Mui-selected': {
    backgroundColor: theme.palette.secondary.light,
    color: theme.palette.primary.contrastText,
  },
}))

export const HalfTab = styled(Tab)(({ theme }) => ({
  outline: 'none !important',
  border: 'none',
  textTransform: 'capitalize',
  borderBottom: '4px solid transparent',
  '&.Mui-selected': {
    color: theme.palette.text.primary,
    fontWeight: 700,
    outline: 'none',
    borderBottom: `2px solid ${theme.palette.primary.main}`,
  },
}))

export const Decor = styled('div')(({ theme, mini }) => ({
  textAlign: 'center',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: mini ? theme.spacing(2) : theme.spacing(3),
  width: mini ? theme.spacing(2) : theme.spacing(3),
  [mini && 'marginRight']: 10,
}))

export const Bogey = styled(Decor)(({ theme }) => ({
  border: `1px solid ${theme.palette.secondary.main}`,
}))

export const DoubleBogey = styled(Decor)(({ theme }) => ({
  boxShadow: [
    ['inset', 0, 0, 0, 1, theme.palette.secondary.main],
    ['inset', 0, 0, 0, 2, 'white'],
    ['inset', 0, 0, 0, 3, theme.palette.secondary.main],
  ],
}))

export const Birdey = styled(Decor)(({ theme }) => ({
  border: `1px solid ${theme.palette.primary.main}`,
  borderRadius: '50%',
}))

export const Eagle = styled(Decor)(({ theme }) => ({
  boxShadow: [
    ['inset', 0, 0, 0, 1, theme.palette.primary.main],
    ['inset', 0, 0, 0, 2, 'white'],
    ['inset', 0, 0, 0, 3, theme.palette.primary.main],
  ],
  borderRadius: '50%',
}))

export const Albatross = styled(Decor)(({ theme }) => ({
  border: `3px solid ${theme.palette.primary.main}`,
  borderRadius: '50%',
}))


export const LegendContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  paddingTop: theme.spacing(3),
  paddingLeft: theme.spacing(1),
  paddingRight: theme.spacing(1),
  justifyContent: 'center',
  flexWrap: 'wrap',
  [theme.breakpoints.up('md')]: {
    paddingRight: 0,
    justifyContent: 'flex-end',
    flexWrap: 'nowrap',
  },
}))

export const Legend = styled('div')(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  marginLeft: theme.spacing(1),
  marginRight: theme.spacing(1),
  marginBottom: theme.spacing(3),
  '& :first-child': {
    left: 0,
  },
  [theme.breakpoints.up('md')]: {
    marginLeft: theme.spacing(5),
    marginRight: 0,
  },
}))


export const useStyles = makeStyles(theme => ({
  lastCell: {
    [theme.breakpoints.up('md')]: {
      borderLeft: `1px solid ${theme.palette.grey.light}`,
      padding: '4px 0',
      width: '100%',
      textAlign: 'center',
      lineHeight: '20px',
    },
  },
  tabsCloseRow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    flexDirection: 'row-reverse',
  },
  closeButton: {
    position: 'relative !important',
    right: '0 !important',
  },
  courseName: {
    color: theme.palette.secondary.light,
    paddingBottom: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(1),
      paddingLeft: theme.spacing(1),
    },
  },
}))
