import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import Typography from '@material-ui/core/Typography'
import Container from '@material-ui/core/Container'
import Box from '@material-ui/core/Box'
import { LeaderboardContext, actionTypes } from './leaderboard.provider'
import LeaderboardHead from './leaderboard-head'
import LeaderboardItem from './leaderboard-item'
import useStyles from './leaderboard.style-hook'

const MyLeaderboard = React.memo((props) => {
  const {
    id,
    feedId,
    limit,
    enableScorecard,
    bordered,
    rounds = 4,
    currentRound,
    enableStatus,
    teamOf20PlayerIds,
    hideThru,
  } = props

  const tournamentCurrentRound = currentRound || props.players[0]?.currentRound || props.players[0]?.rounds.length

  const styles = useStyles()
  const updatedPlayers = props.players
  const { state, dispatch } = useContext(LeaderboardContext)
  const { players } = state[id] || []

  const removePlayerFav = (player) => {
    dispatch({ type: actionTypes.REMOVE_PLAYER, payload: { tournamentId: id, player } })
  }

  const filterPlayers = (oldPlayers, newPlayers) => {
    if (!oldPlayers || !newPlayers) { return false }
    const outPlayers = []
    newPlayers.forEach((newPlayer) => {
      oldPlayers.forEach((oldPlayer) => {
        if (newPlayer.player && oldPlayer.player
          && newPlayer.player.playerId === oldPlayer.player.playerId) {
          outPlayers.push(newPlayer)
        }
      })
    })
    return outPlayers
  }

  const defineTeam20Data = (playerId, team20Players) => {
    if (!team20Players || !team20Players[feedId] || !Array.isArray(team20Players[feedId])) return null
    let team20Data
    if (typeof team20Players[feedId][0] === 'object') {
      team20Data = team20Players[feedId].find(player => player.id === playerId)
    } else if (typeof team20Players[feedId][0] === 'string') {
      team20Data = team20Players[feedId].includes(playerId)
    }
    return team20Data
  }

  const filteredPlayers = filterPlayers(players, updatedPlayers)
  const orderedPlayers = filteredPlayers
    ? filteredPlayers.sort((a, b) => a.sortIndex - b.sortIndex)
    : []
  const show = limit ? orderedPlayers.slice(0, limit) : orderedPlayers

  return show.length > 0 ? (
    <Box py={[4, 6, 8]} bgcolor="grey.200" mt={[4, 6, 8]}>
      <Container
        data-cy="my-leaderboard"
        className={styles.myLeaderboard}
      >
        {enableScorecard && (
          <>
            <div>
              <Typography variant="subtitle2" className={styles.descriptionTitle}>My Leaderboard</Typography>
              <LeaderboardHead rounds={Number(tournamentCurrentRound)} showFavorite hideThru={hideThru} />
            </div>

            {show && show.map(player => (
              player && player.player && (
                <LeaderboardItem
                  key={player.player.playerId}
                  bordered={bordered}
                  {...player}
                  tournamentId={id}
                  feedId={feedId}
                  enableScorecard={enableScorecard}
                  totalRounds={rounds}
                  removeItem={removePlayerFav}
                  enableStatus={enableStatus}
                  rounds={player.rounds}
                  tournamentCurrentRound={Number(tournamentCurrentRound)}
                  team20Data={defineTeam20Data(player.player.playerId, teamOf20PlayerIds)}
                  saved
                  leaderboardSaved
                  showFavorite
                  hideThru={hideThru}
                />
              )
            ))}
          </>
        )}
      </Container>
    </Box>
  ) : null
})

MyLeaderboard.propTypes = {
  bordered: PropTypes.bool,
  enableScorecard: PropTypes.bool,
  feedId: PropTypes.string,
  id: PropTypes.string,
  limit: PropTypes.number,
  players: PropTypes.arrayOf(PropTypes.shape({
    player: PropTypes.shape({
      playerId: PropTypes.string,
    }),
  })),
  rounds: PropTypes.number,
  currentRound: PropTypes.string,
  enableStatus: PropTypes.bool,
  teamOf20PlayerIds: PropTypes.object,
  hideThru: PropTypes.bool,
}

MyLeaderboard.defaultProps = {
  players: [],
}

export default MyLeaderboard
