import PropTypes from 'prop-types'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import useStyles from './leaderboard-cut-line.styles'

const LeaderboardCutLine = (props) => {
  const {
    cutScore,
    cutRound,
    currentRound,
    currentRoundState,
    team20CutLine,
    cutLineText,
    cutLineTextOverride,
  } = props
  const styles = useStyles()

  const displayProjectedCutLineStatuses = ['In Progress', 'Suspended']
  const doNotDisplayCutLineStatuses = ['Not Started', 'Cancelled', 'Maintenance']
  const projectedCutLineText = 'Projected Cut'
  let displayedCutScore = cutScore > 0 ? `+${cutScore}` : cutScore
  if (cutScore === 0) {
    displayedCutScore = 'E'
  }
  let hideCutLine = team20CutLine ? false : (cutScore === null || cutScore === undefined
    || (currentRound === cutRound && doNotDisplayCutLineStatuses.includes(currentRoundState)))

  let displayedCutLineText = ''

  if (+currentRound === cutRound && (displayProjectedCutLineStatuses.includes(currentRoundState))) {
    displayedCutLineText = `${projectedCutLineText}`
  } else {
    displayedCutLineText = `${cutLineText || 'The following players did not make the cut:'} ${displayedCutScore}`
  }

  if (cutLineTextOverride) {
    displayedCutLineText = cutLineTextOverride
    hideCutLine = false
  }

  if (team20CutLine) {
    displayedCutLineText = cutLineText || 'Corebridge Financial PGA Team (Low 20 players only)'
  }

  return !hideCutLine ? (
    <Box className={styles.root}>
      <Typography variant="body1">
        {displayedCutLineText}
      </Typography>
    </Box>
  ) : null
}

LeaderboardCutLine.propTypes = {
  cutScore: PropTypes.number,
  cutRound: PropTypes.number,
  currentRound: PropTypes.string,
  currentRoundState: PropTypes.string,
  team20CutLine: PropTypes.bool,
  cutLineText: PropTypes.string,
  cutLineTextOverride: PropTypes.string,
}

export default LeaderboardCutLine
