import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  ad: {
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
    marginLeft: 'auto',
  },
  mobileAd: {
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
    display: 'flex',
    justifyContent: 'center',
    height: '70px',
    paddingBottom: 5,
  },
}))

export default useStyles
