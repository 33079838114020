import React, { useReducer, useEffect } from 'react'
import PropTypes from 'prop-types'

const actionTypes = {
  SET_INITIAL_STATE: 'setInitialstate',
  ADD_PLAYER: 'addPlayer',
  REMOVE_PLAYER: 'removePlayer',
}

const initialState = {}

const reducer = (state, action) => {
  switch (action.type) {
  case actionTypes.SET_INITIAL_STATE: {
    return action.payload.state
  }
  case actionTypes.ADD_PLAYER: {
    const tournament = state[action.payload.tournamentId]
    const cutenrPlayers = tournament ? tournament.players : []
    const newState = {
      ...state,
      [action.payload.tournamentId]: { players: [...cutenrPlayers, action.payload.player] },
    }
    localStorage.setItem('myLeaderBoard', JSON.stringify(newState))
    return newState
  }
  case actionTypes.REMOVE_PLAYER: {
    const tournament = state[action.payload.tournamentId]
    const targetPlayerId = action.payload.player.player.playerId
    const cutenrPlayers = tournament
      ? tournament.players.filter(item => targetPlayerId !== item.player.playerId)
      : []
    const newState = { ...state, [action.payload.tournamentId]: { players: cutenrPlayers } }
    localStorage.setItem('myLeaderBoard', JSON.stringify(newState))
    return newState
  }
  default:
    return { ...state }
  }
}

const LeaderboardContext = React.createContext(initialState)

const LeaderboardProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState)

  useEffect(
    () => {
      dispatch({
        type: actionTypes.SET_INITIAL_STATE,
        payload: { state: JSON.parse(localStorage.getItem('myLeaderBoard')) || {} },
      })
    },
    [],
  )

  return (
    <LeaderboardContext.Provider value={{ state, dispatch }}>
      {children}
    </LeaderboardContext.Provider>
  )
}


LeaderboardProvider.propTypes = {
  children: PropTypes.node,
}

export { LeaderboardContext, LeaderboardProvider, actionTypes }
