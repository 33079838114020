import { styled, makeStyles } from '@material-ui/core/styles'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import { eyebrowFont, headlineFont } from '@config/mui-theme'

export const ScorecardRoot = styled('div')(({ theme }) => ({
  color: theme.palette.secondary.main,
  width: '100%',
}))

export const TurnBox = styled('div')(({ theme }) => ({
  textTransform: 'uppercase',
  width: '100%',
  [theme.breakpoints.up('md')]: {
    width: '50%',
  },
}))

export const CellBox = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderTop: `1px solid ${theme.palette.grey.light}`,
  height: 40,
  fontWeight: 700,
  fontFamily: eyebrowFont,
  letterSpacing: '1.5px',
  color: theme.palette.common.black,
  '&:nth-child(even)': {
    height: '35px',
    fontFamily: headlineFont,
  },
  '&:first-child': {
    marginTop: 0,
  },
  '&:last-child': {
    borderBottom: `1px solid ${theme.palette.grey.light}`,
  },
}))

export const ScoreCellBox = styled(CellBox)(() => ({
  position: 'relative',
  height: 40,
}))

export const ColBox = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: theme.spacing(5),
  flexGrow: 1,
}))

export const FirstCol = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    width: theme.spacing(10),
  },
  [theme.breakpoints.down('sm')]: {
    width: theme.spacing(2),
  },
}))

export const FirstCell = styled(CellBox)(({ theme }) => ({
  position: 'relative',
  justifyContent: 'flex-start',
  textAlign: 'left',
  ...theme.typography.caption,
  color: theme.palette.common.black,
  fontWeight: 700,
  fontFamily: eyebrowFont,
  letterSpacing: '1.5px',
  borderLeft: `4px solid ${theme.palette.primary.main}`,
  '&:nth-child(1)': {
    borderLeft: `4px solid ${theme.palette.secondary.light}`,
  },
  [theme.breakpoints.up('md')]: {
    paddingLeft: theme.spacing(2),
    '& div': {
      borderRight: `1px solid ${theme.palette.grey.light}`,
      width: '100%',
      padding: '2px 0',
    },
  },
  [theme.breakpoints.down('sm')]: {
    '& div': {
      flexShrink: 0,
      transform: 'rotate(-90deg) translate(-28px, 0)',
      transformOrigin: 'top left',
      textAlign: 'center',
      width: 40,
      fontSize: '0.625rem',
    },
  },
}))

export const NameCell = styled(CellBox)(({ theme }) => ({
  position: 'relative',
  textTransform: 'capitalize',
  justifyContent: 'flex-start',
  textAlign: 'left',
  color: theme.palette.common.black,
  borderLeft: 'none',
  width: '100%',
  whiteSpace: 'nowrap',
  '& p': {
    fontSize: '1rem',
    fontFamily: headlineFont,
    color: theme.palette.secondary.light,
    fontWeight: 600,
    letterSpacing: 0,
    paddingTop: theme.spacing(1),
  },
}))

export const RoundTabs = styled(Tabs)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  marginBottom: theme.spacing(1),
  '& .MuiTabs-indicator': {
    display: 'none',
  },
  [theme.breakpoints.down('xs')]: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
}))

export const RoundTab = styled(Tab)(({ theme }) => ({
  ...theme.typography.formLabels,
  fontWeight: 700,
  minHeight: 0,
  minWidth: 0,
  height: '29px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '0px 10px',
  marginRight: '0.625rem',
  borderRadius: '6px',
  backgroundColor: 'none',
  color: theme.palette.grey.dark,
  textDecoration: 'none',
  textTransform: 'capitalize',
  opacity: 1,
  outline: 'none !important',
  '&.Mui-selected': {
    backgroundColor: theme.palette.secondary.light,
    color: theme.palette.primary.contrastText,
  },
}))

export const HalfTab = styled(Tab)(({ theme }) => ({
  outline: 'none !important',
  border: 'none',
  textTransform: 'capitalize',
  borderBottom: '4px solid transparent',
  '&.Mui-selected': {
    color: theme.palette.text.primary,
    fontWeight: 700,
    outline: 'none',
    borderBottom: `2px solid ${theme.palette.primary.main}`,
  },
}))

export const Decor = styled('div')(({ theme, mini }) => ({
  textAlign: 'center',
  fontWeight: 600,
  height: mini ? theme.spacing(2) : theme.spacing(3),
  width: mini ? theme.spacing(2) : theme.spacing(3),
  [mini && 'marginRight']: 10,
}))

export const Bogey = styled(Decor)(({ theme }) => ({
  border: `1px solid ${theme.palette.secondary.main}`,
}))

export const DoubleBogey = styled(Decor)(({ theme }) => ({
  boxShadow: [
    ['inset', 0, 0, 0, 1, theme.palette.secondary.main],
    ['inset', 0, 0, 0, 2, 'white'],
    ['inset', 0, 0, 0, 3, theme.palette.secondary.main],
  ],
}))

export const Birdey = styled(Decor)(({ theme }) => ({
  border: `1px solid ${theme.palette.primary.main}`,
  borderRadius: '50%',
}))

export const Eagle = styled(Decor)(({ theme }) => ({
  boxShadow: [
    ['inset', 0, 0, 0, 1, theme.palette.primary.main],
    ['inset', 0, 0, 0, 2, 'white'],
    ['inset', 0, 0, 0, 3, theme.palette.primary.main],
  ],
  borderRadius: '50%',
}))

export const Albatross = styled(Decor)(({ theme }) => ({
  border: `3px solid ${theme.palette.primary.main}`,
  borderRadius: '50%',
}))

export const Start = styled(Decor)(({ theme }) => ({
  borderTop: `3px solid ${theme.palette.accent.main}`,
  lineHeight: '19px',
}))

export const Win = styled(Decor)(({ theme }) => ({
  borderBottom: `3px solid ${theme.palette.blue.main}`,
  fontWeight: 600,
  fontSize: '14px',
  lineHeight: '25px',
}))

export const LegendContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  paddingTop: theme.spacing(3),
  paddingLeft: theme.spacing(1),
  paddingRight: theme.spacing(1),
  justifyContent: 'center',
  flexWrap: 'wrap',
  [theme.breakpoints.up('md')]: {
    paddingRight: theme.spacing(4),
    justifyContent: 'flex-end',
    flexWrap: 'nowrap',
  },
}))

export const Legend = styled('div')(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  marginLeft: theme.spacing(1),
  marginRight: theme.spacing(1),
  marginBottom: theme.spacing(3),
  '& :first-child': {
    left: 0,
  },
  [theme.breakpoints.up('md')]: {
    marginLeft: theme.spacing(5),
    marginRight: 0,
  },
}))

export const useStyles = makeStyles(theme => ({
  pageWrapper: ({ fullPage }) => ({
    maxWidth: '1440px',
    margin: '0 auto',
    paddingTop: !fullPage && theme.spacing(2),
  }),
  leaderboardWrapper: ({ nested }) => ({
    paddingTop: !nested ? theme.spacing(2) : 0,
  }),
  disclaimerWrapper: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(3),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  fullLeaderboard: {
    marginBottom: theme.spacing(1),
    textTransform: 'capitalize',
    fontSize: '1.125rem',
    color: theme.palette.text.primary,
    fontWeight: 700,
  },
  disclaimer: {
    fontSize: '0.875rem',
    color: theme.palette.grey.main,
    fontWeight: 700,
  },
  disclaimerLanding: {
    marginBottom: -theme.spacing(3),
    [theme.breakpoints.up('md')]: {
      marginBottom: -theme.spacing(1),
    },
  },
  active: {
    backgroundColor: theme.palette.secondary.light,
    color: theme.palette.primary.contrastText,
    '&:hover': {
      backgroundColor: theme.palette.secondary.light,
      color: theme.palette.primary.contrastText,
    },
  },
  teamHeader: {
    display: 'flex',
    height: '40px',
    borderBottom: '1px solid lightgray',
    [theme.breakpoints.down('xs')]: {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
  },
  outerItem: ({ showLeaderboard }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: showLeaderboard ? '8px 8px 0 0' : '8px',
    height: '88px',
    borderTop: showLeaderboard ? `8px solid ${theme.palette.primary.main}` : `8px solid ${theme.palette.offwhite.main}`,
    backgroundColor: theme.palette.opaque.white,
    color: theme.palette.secondary.main,
    boxShadow: theme.palette.shadow.cardBoxShadow,
    marginBottom: 0,
    [theme.breakpoints.down('xs')]: {
      height: '110px',
    },
    [theme.breakpoints.up('lg')]: {
      height: '102px',
    },
    '&:hover': {
      cursor: 'pointer',
    },
    '&>div': {
      width: '100%',
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column-reverse',
        alignItems: 'flex-start',
        justifyContent: 'center',
      },
    },
  }),
  chevronWrapper: {
    display: 'flex',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      justifyContent: 'space-between',
    },
  },
  statusWrapper: {
    display: 'flex',
    [theme.breakpoints.down('xs')]: {
      paddingBottom: theme.spacing(1),
    },
  },
  winnerTag: {
    padding: '5px 10px',
    ...theme.typography.formLabels,
    lineHeight: '19px',
    fontWeight: 700,
    color: theme.palette.text.primary,
    [theme.breakpoints.down('xs')]: {
      paddingLeft: 0,
    },
  },
  progressTag: {
    padding: '5px 10px',
    backgroundColor: theme.palette.blue.light,
    borderRadius: '8px',
    ...theme.typography.formLabels,
    lineHeight: '19px',
    fontWeight: 700,
  },
  completed: {
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.primary.contrastText,
  },
  flagHeader: {
    color: theme.palette.grey.dark,
    ...theme.typography.cardItemCategory,
    letterSpacing: '1.5px',
    fontWeight: 700,
    borderBottom: '1px solid lightgray',
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(1),
    [theme.breakpoints.down('xs')]: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
  },
  teamColumnHeader: {
    display: 'flex',
    flexBasis: '45%',
    [theme.breakpoints.down('xs')]: {
      minWidth: 100,
    },
  },
  flagColumnHeader: {
    display: 'flex',
    flexBasis: '10%',
    alignItems: 'center',
    justifyContent: 'flex-start',
    '&:last-child': {
      flexGrow: 1,
      justifyContent: 'flex-end',
    },
  },
  teamsContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  teamSection: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    borderBottom: '1px solid lightgray',
  },
  teamRow: {
    display: 'flex',
    position: 'relative',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    color: theme.palette.text.primary,
    fontWeight: '700 !important',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    '&:hover': {
      cursor: 'pointer',
    },
    [theme.breakpoints.down('xs')]: {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
  },
  scoreWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '20%',
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'flex-end',
      width: 'max-content',
    },
  },
  scoreWrapperHeader: {
    width: '25%',
    [theme.breakpoints.down('xs')]: {
      width: 'max-content',
    },
  },
  teamRowText: {
    ...theme.typography.formLabels,
  },
  leftItem: {
    width: '15px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'flex-end',
    },
  },
  teamCellWrapper: {
    display: 'flex',
    alignItems: 'center',
    flexBasis: '45%',
    paddingRight: theme.spacing(8),
    [theme.breakpoints.down('xs')]: {
      minWidth: 100,
      paddingRight: 0,
      flexBasis: '80%',
    },
  },
  teamCell: {
    alignItems: 'center',
    justifyContent: 'flex-start',
    paddingRight: theme.spacing(1),
  },
  teamClause: {
    display: 'inline',
    fontWeight: 700,
  },
  vs: {
    color: theme.palette.grey.dark,
  },
  itemCell: {
    display: 'flex',
    flexBasis: '10%',
    alignItems: 'center',
    '&>p': {
      fontWeight: 700,
    },
    '&:last-child': {
      flexGrow: 1,
      justifyContent: 'flex-end',
    },
  },
  left: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  chevron: {
    color: theme.palette.grey.main,
    transitionProperty: 'transform',
    transitionDuration: theme.transitions.duration.shortest,
    transitionTimingFunction: theme.transitions.easing.easeInOut,
    '&.expanded': {
      transform: 'rotate(-180deg)',
    },
  },
  internalScorecard: {
    display: 'flex',
    width: '100%',
    marginTop: theme.spacing(1),
    paddingTop: 0,
  },
  lastCell: {
    [theme.breakpoints.up('md')]: {
      borderLeft: `1px solid ${theme.palette.grey.light}`,
      padding: '4px 0',
      width: '100%',
      textAlign: 'center',
      lineHeight: '20px',
    },
  },
  teeTime: {
    textAlign: 'center',
    lineHeight: 1,
    '& div': {
      paddingTop: 10,
    },
    '& span': {
      display: 'block',
      textTransform: 'uppercase',
      fontSize: 10,
      color: theme.palette.grey.dark,
    },
  },
  labelRank: {
    textTransform: 'uppercase',
    color: theme.palette.secondary.contrastText,
    fontSize: '14px',
    paddingRight: 0,
    paddingLeft: 0,
    [theme.breakpoints.only('sm')]: {
      fontSize: '11px',
    },
  },
  labelValue: {
    display: 'flex',
    width: theme.spacing(6),
    height: theme.spacing(5),
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.secondary.contrastText,
    fontFamily: theme.typography.fontFamily,
    fontWeight: 'bold',
    textTransform: 'uppercase',
    letterSpacing: 1.5,
    fontSize: '14px',
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(6),
    },
    [theme.breakpoints.up('md')]: {
      width: theme.spacing(10),
    },
    [theme.breakpoints.only('sm')]: {
      fontSize: '11px',
    },
  },
  rank: {
    display: 'flex',
    width: theme.spacing(4),
    paddingRight: theme.spacing(2),
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 14,
    fontFamily: theme.typography.fontFamily,
    fontWeight: 'bold',
    [theme.breakpoints.up('md')]: {
      width: theme.spacing(8),
    },
    [theme.breakpoints.down('xs')]: {
      paddingLeft: theme.spacing(2),
    },
  },
  itemName: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    alignItems: 'left',
    justifyContent: 'center',
    paddingRight: 0,
    paddingLeft: theme.spacing(2),
    minWidth: 120,
    [theme.breakpoints.down('xs')]: {
      minWidth: 100,
    },
  },
  headContainer: ({ showFavorite, nested }) => ({
    paddingLeft: showFavorite ? 65 : 20,
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
    paddingRight: theme.spacing(1.5),
    boxShadow: theme.palette.shadow.cardBoxShadow,
    borderRadius: nested ? 0 : '8px 8px 0 0',
    marginBottom: 0,
    [theme.breakpoints.down('xs')]: {
      paddingLeft: showFavorite ? 50 : 4,
      paddingRight: 0,
    },
    '&>div': {
      ...theme.typography.overline,
      display: 'flex',
    },
    '&>div:nth-child(2)': {
      [theme.breakpoints.down('sm')]: {
        paddingLeft: theme.spacing(2),
      },
      [theme.breakpoints.down('xs')]: {
        paddingLeft: theme.spacing(3),
      },
    },
    '&>div:nth-child(3)': {
      [theme.breakpoints.down('xs')]: {
        paddingLeft: theme.spacing(1),
      },
    },
  }),
  hideMobile: {
    [theme.breakpoints.down('xs')]: {
      display: 'none !important',
    },
  },
  hideSmall: {
    [theme.breakpoints.down('sm')]: {
      display: 'none !important',
    },
  },
  courseName: {
    color: theme.palette.secondary.light,
  },
  tabsCloseRow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    flexDirection: 'row-reverse',
  },
  closeButton: {
    position: 'relative !important',
    right: '0 !important',
  },
}))

export default useStyles
