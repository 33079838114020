import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  root: {
    background: '#E0E0E0',
    color: theme.palette.text.primary,
    padding: theme.spacing(2),
    marginBottom: theme.spacing(1),
    '& p': {
      textAlign: 'center',
      fontWeight: 700,
      [theme.breakpoints.only('xs')]: {
        fontSize: '12px',
      },
    },
  },
}))

export default useStyles
