/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import ReactCountryFlag from 'react-country-flag'
import Box from '@material-ui/core/Box'
import IconButton from '@material-ui/core/IconButton'
import StarBorderIcon from '@material-ui/icons/StarBorder'
import StarIcon from '@material-ui/icons/Star'
import Typography from '@material-ui/core/Typography'
import Tooltip from '@material-ui/core/Tooltip'
import { useTheme } from '@material-ui/core/styles'
import { ScorecardQuery } from '@components/scorecard'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import TrophyIcon from '@components/atoms/icons/trophy'
import { Image } from '@components/atoms'
import { gtmEvent } from '@lib/gtm-utils'
import useStyles from './leaderboard.style-hook'

const cutOrWithdrawn = (currentPosition) => {
  return currentPosition === 'WD' || currentPosition === 'CUT'
}

const isThruComplete = (thru) => {
  return thru === 'F' || thru === 'F*'
}

const formatRound = (round, currentPosition) => {
  if (cutOrWithdrawn(currentPosition)) {
    return '-'
  }
  return round && isThruComplete(round.thru) ? round.stroke : '-'
}

const formatTournStroke = (stroke) => {
  return stroke === 0 ? '-' : stroke
}

const formatScore = (score) => {
  if (!score) return '-'
  if (score === '0') return 'E'
  if (score > 0) return `+${score}`
  return score
}

const formatThru = (thru, teeTime, currentPosition) => {
  if (cutOrWithdrawn(currentPosition)) {
    return '-'
  }
  if (teeTime === '00:00:00') {
    return thru || '-'
  }
  if (
    (thru === null || thru === undefined || thru === '-' || thru === '*' || thru === '-*')
    && teeTime
  ) {
    const hour = teeTime && teeTime.substring(0, 2)
    const correctHour = hour >= 13 ? hour - 12 : hour
    const min = teeTime && teeTime.substring(3, 5)
    const amPm = hour >= 12 ? 'pm' : 'am'
    const back9 = thru === '-*' ? '*' : ''
    const teeFormatted = (
      <div>
        {correctHour}:{min}
        <span>
          {amPm}
          {back9}
        </span>
      </div>
    )
    return teeFormatted
  }
  return thru
}

const LeaderboardItem = React.memo((props) => {
  const [showScorecard, toggleScoreCard] = useState(false)
  const theme = useTheme()
  const styles = useStyles(props)
  const {
    bordered,
    currentPosition,
    player,
    tournamentRelativePar,
    thru,
    currentRound,
    rounds,
    teeTime,
    tournamentStreak,
    currentRelativePar,
    tournamentId,
    feedId,
    enableScorecard,
    addItem,
    removeItem,
    saved,
    streamUrl,
    liveStreamUrl,
    leaderboardSaved,
    enableStatus,
    team20Data,
    showFavorite,
    winner,
    tournamentCurrentRound,
    hideThru,
    eventName,
  } = props

  const isMobile = useMediaQuery(useTheme().breakpoints.down('xs'))
  const underPar = tournamentRelativePar < 0
  const tournamentRelativeParFixed = formatScore(tournamentRelativePar)

  const sendEventTracking = action => gtmEvent({
    event: 'leaderboard-interaction',
    attributes: {
      tour_name: feedId,
      title: eventName,
      player_name: `${player?.firstName} ${player?.lastName}`,
      position: currentPosition,
      action,
      total: tournamentRelativePar,
      strokes: tournamentStreak,
      country: player?.country,
    },
  })

  const handleToggle = () => {
    if (window && window.dataLayer) {
      window.dataLayer.push({
        playerName: player && `${player.firstName} ${player.lastName}`,
      })
    }
    sendEventTracking(showScorecard ? 'close' : 'open')
    if (enableScorecard && rounds) toggleScoreCard(!showScorecard)
  }

  const clickStar = (e) => {
    e.stopPropagation()
    sendEventTracking('star')
    if (saved) {
      removeItem(props)
    } else {
      addItem(props)
    }
  }

  const handleLogoClick = (e, href) => {
    if (!href) return
    e.stopPropagation()
    window.open(href, '_blank')
  }

  return (
    <div className={styles.outer} data-cy="leaderboard-item">
      <div
        className={`${styles.item} ${styles.clickHover} ${bordered ? styles.bordered : ''}`}
        role="row"
        onClick={handleToggle}
        tabIndex={0}
      >
        {showFavorite && (
          <IconButton
            aria-label="favorite"
            onClick={clickStar}
            role="button"
            className={styles.favoriteButton}
          >
            {saved ? (
              <StarIcon color={leaderboardSaved && 'secondary'} />
            ) : (
              <StarBorderIcon />
            )}
          </IconButton>
        )}
        <Box className={styles.rank} pl={showFavorite ? 0 : 2} role="cell" aria-label={`position: ${currentPosition}`}>
          {currentPosition}
        </Box>
        {(!isMobile) && (
          <figure
            className={`${styles.itemFlag}  ${!player.country ? 'hide' : ''}`}
            role="cell"
          >
            <ReactCountryFlag
              countryCode={player.country || ''}
              svg
              cdnUrl={
                player.country && player.country.toLowerCase() === 'tw'
                  ? '/flags/'
                  : 'https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.6/flags/1x1/'
              }
              style={{ width: '100%', height: '100%' }}
              title={`player country: ${player.country}`}
            />
          </figure>
        )}
        <div className={styles.dataWrapper} role="cell">
          <div className={styles.itemNameWrapper}>
            <div
              className={`${styles.itemName} ${enableScorecard && rounds ? styles.clickHover : null
              }`}
              data-gtm-player-name={
                player && `${player.firstName} ${player.lastName}`
              }
              data-gtm-round={currentRound}
              data-cy="item-name"
            >
              <div className={styles.firstName}>{player && player.firstName}</div>
              <Typography className={styles.lastName} variant="h6">
                {player && player.lastName}
              </Typography>
            </div>
            <KeyboardArrowDownIcon className={`${styles.chevron} ${showScorecard ? 'expanded' : ''}`} />
            {winner && <TrophyIcon />}
          </div>
          <div className={styles.logoWrapper}>
            {team20Data?.logo ? (
              <Image
                className={styles.teamOf20Logo}
                src={team20Data.logo}
                alt={team20Data.altText || 'Team of 20 Logo'}
                onClick={e => handleLogoClick(e, team20Data.href)}
              />
            ) : null}
          </div>
        </div>
        {streamUrl && liveStreamUrl && (
          <div className={`${styles.itemStreamUrl} ${styles.hideMobile}`}>
            <a href={liveStreamUrl} target="_blank" rel="noopener noreferrer">
              <Tooltip title="Watch Featured Groups">
                <div className={styles.liveStreamIcon} />
              </Tooltip>
            </a>
          </div>
        )}

        <div
          className={styles.itemValue}
          style={{ color: underPar ? theme.palette.accent.red : null }}
          role="cell"
          aria-label={`total score: ${tournamentRelativeParFixed}`}
        >
          {tournamentRelativeParFixed}
        </div>
        {
          !hideThru && (
            <div
              className={`${styles.itemValue} ${styles.teeTime}`}
              role="cell"
              aria-label={(!teeTime || teeTime === '00:00:00') ? `through ${thru}` : 'tee time'}
            >
              {formatThru(thru, teeTime, currentPosition)}
            </div>
          )
        }
        {tournamentCurrentRound >= 2 && (
          <div
            className={`${styles.itemValue} ${styles.itemScore}`}
            role="cell"
            aria-label={`current round score: ${currentRelativePar}`}
          >
            {formatScore(currentRelativePar, teeTime)}
          </div>
        )}
        <div
          className={`${styles.itemValue} ${styles.hideMobile}`}
          role="cell"
          aria-label={`first round score: ${rounds ? formatRound(rounds[0]) : '-'}`}
        >
          {rounds ? formatRound(rounds[0]) : '-'}
        </div>
        {tournamentCurrentRound >= 2 && (
          <div
            className={`${styles.itemValue} ${styles.hideMobile}`}
            role="cell"
            aria-label={`second round score: ${rounds ? formatRound(rounds[1]) : '-'}`}
          >
            {rounds ? formatRound(rounds[1]) : '-'}
          </div>
        )}
        {tournamentCurrentRound >= 3 && (
          <div
            className={`${styles.itemValue} ${styles.hideMobile}`}
            role="cell"
            aria-label={`third round score: ${rounds ? formatRound(rounds[2]) : '-'}`}
          >
            {rounds ? formatRound(rounds[2]) : '-'}
          </div>
        )}
        {tournamentCurrentRound >= 4 && (
          <div
            className={`${styles.itemValue} ${styles.hideMobile}`}
            role="cell"
            aria-label={`fourth round score: ${rounds ? formatRound(rounds[3]) : '-'}`}
          >
            {rounds ? formatRound(rounds[3]) : '-'}
          </div>
        )}
        <div
          className={`${styles.itemValue} ${styles.hideMobile}`}
          role="cell"
          aria-label={`total strokes: ${tournamentStreak ? formatTournStroke(tournamentStreak) : '-'}`}
        >
          {tournamentStreak ? formatTournStroke(tournamentStreak) : '-'}
        </div>
      </div>

      {showScorecard && (
        <Box
          position="relative"
          pt={[4, 0]}
          pl={[0, 0, 10]}
          pr={[0, 0, 4]}
          pb={2}
          className={styles.scorecard}
        >
          <ScorecardQuery
            currentRound={currentRound}
            playerId={player && player.playerId}
            tournamentId={tournamentId}
            feedId={feedId}
            handleToggle={handleToggle}
            thru={thru}
            name={player && `${player.firstName} ${player.lastName}`}
            enableStatus={enableStatus}
            handleTrackingEvent={sendEventTracking}
          />
        </Box>
      )}
    </div>
  )
})

LeaderboardItem.propTypes = {
  bordered: PropTypes.bool,
  currentPosition: PropTypes.string,
  feedId: PropTypes.string,
  tournamentId: PropTypes.string,
  player: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    playerId: PropTypes.string,
    country: PropTypes.string,
  }),
  streamUrl: PropTypes.string,
  liveStreamUrl: PropTypes.string,
  tournamentRelativePar: PropTypes.string,
  teeTime: PropTypes.string,
  thru: PropTypes.string,
  currentRound: PropTypes.number,
  currentRelativePar: PropTypes.string,
  tournamentStreak: PropTypes.number,
  rounds: PropTypes.array,
  enableScorecard: PropTypes.bool,
  saved: PropTypes.bool,
  leaderboardSaved: PropTypes.bool,
  addItem: PropTypes.func,
  removeItem: PropTypes.func,
  enableStatus: PropTypes.bool,
  team20Data: PropTypes.oneOfType([
    PropTypes.shape({
      id: PropTypes.string,
      logo: PropTypes.string,
      logoWidth: PropTypes.string,
      logoWidthSm: PropTypes.string,
      logoWidthXs: PropTypes.string,
      href: PropTypes.string,
      altText: PropTypes.string,
      backgroundColor: PropTypes.string,
    }),
    PropTypes.bool,
  ]),
  showFavorite: PropTypes.bool,
  winner: PropTypes.bool,
  tournamentCurrentRound: PropTypes.number,
  hideThru: PropTypes.bool,
  eventName: PropTypes.string,
}

LeaderboardItem.defaultProps = {}

export default LeaderboardItem
