import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import { styled } from '@material-ui/core/styles'


export const Outer = styled('div')(({ theme }) => ({
  background: theme.palette.common.white,
  marginBottom: theme.spacing(1),
}))

export const ItemRoot = styled('div')(({ theme }) => ({
  display: 'flex',
  color: theme.palette.common.black,
  padding: theme.spacing(2),
  [theme.breakpoints.down('xs')]: {
    paddingRight: 0,
  },
}))

// POSITION AND NAME

export const ItemRank = styled('div')(({ theme }) => ({
  display: 'flex',
  width: theme.spacing(4),
  paddingRight: theme.spacing(2),
  alignItems: 'center',
  justifyContent: 'center',
  borderRight: `1px solid ${theme.palette.divider}`,
  color: theme.palette.grey[600],
  fontSize: 11,
  fontWeight: 'bold',
  [theme.breakpoints.up('md')]: {
    width: theme.spacing(8),
  },
}))

export const LabelRank = styled(ItemRank)(({ theme }) => ({
  textTransform: 'uppercase',
  color: theme.palette.secondary.contrastText,
  fontSize: '12px',
}))

export const ItemName = styled('div')(({ theme, hover }) => ({
  display: 'flex',
  flexGrow: 1,
  flexDirection: 'column',
  alignItems: 'left',
  justifyContent: 'center',
  paddingRight: theme.spacing(2),
  paddingLeft: theme.spacing(2),
  minWidth: 120,
  '&:hover': {
    cursor: hover,
  },
}))

export const FirstName = styled('div')(({ theme }) => ({
  color: theme.palette.grey.dark,
  fontSize: 12,
  lineHeight: 1,
}))

export const LastName = styled(Typography)(() => ({
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
}))

// CELLS

export const ItemValue = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: theme.spacing(6),
  height: theme.spacing(5),
  fontWeight: 'bold',
  [theme.breakpoints.up('sm')]: {
    width: theme.spacing(6),
    '&:not(:last-child)': {
      borderRight: `1px solid ${theme.palette.grey[200]}`,
    },
  },
  [theme.breakpoints.up('md')]: {
    width: theme.spacing(10),
  },
}))

export const ItemValueHideMobile = styled(ItemValue)(({ theme }) => ({
  [theme.breakpoints.down('xs')]: {
    display: 'none',
  },
}))

export const LabelValue = styled('div')(({ theme }) => ({
  display: 'flex',
  width: theme.spacing(6),
  height: theme.spacing(7),
  alignItems: 'center',
  justifyContent: 'center',
  color: theme.palette.secondary.contrastText,
  fontWeight: 'bold',
  textTransform: 'uppercase',
  fontSize: '12px',
  [theme.breakpoints.up('sm')]: {
    width: theme.spacing(6),
  },
  [theme.breakpoints.up('md')]: {
    width: theme.spacing(10),
  },
}))

export const LabelValueHideMobile = styled(LabelValue)(({ theme }) => ({
  [theme.breakpoints.down('xs')]: {
    display: 'none',
  },
}))

export const HideTextMobile = styled('span')(({ theme }) => ({
  [theme.breakpoints.down('xs')]: {
    display: 'none',
  },
}))

export const CloseButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  top: 0,
  zIndex: 1,
  right: 0,
  [theme.breakpoints.up('md')]: {
    right: theme.spacing(3),
  },
}))

export const ScorecardWrap = styled('div')(({ theme }) => ({
  padding: `${theme.spacing(4)} 0`,
  position: 'relative',
  [theme.breakpoints.up('md')]: {
    paddingTop: theme.spacing(0),
    paddingLeft: theme.spacing(10),
    paddingRight: theme.spacing(4),
  },
}))
