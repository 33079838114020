import PropTypes from 'prop-types'
import Box from '@material-ui/core/Box'
import useStyles from './leaderboard.style-hook'

const LeaderboardHead = (props) => {
  const {
    rounds = 4,
    showFavorite,
    nested,
    hideThru,
  } = props
  const styles = useStyles({ nested })

  return (
    <Box
      display="flex"
      pl={showFavorite ? [6, 7] : [1, 2]}
      pr={[0, 2]}
      mb={1}
      data-cy="leaderboard-head"
      className={styles.topRounding}
      role="row"
    >
      <div className={`${styles.rank} ${styles.labelRank}`} role="columnheader" aria-label="position">POS</div>
      <div className={styles.dataWrapper} />
      <div className={styles.labelValue} aria-label="total score" role="columnheader">
        <div aria-hidden="true">Tot<span className={styles.hideMobile}>al</span></div>
      </div>
      {!hideThru && <div className={styles.labelValue} role="columnheader" aria-label="through">Thru</div>}
      {rounds >= 2 && <div className={styles.labelValue} role="columnheader" aria-label="round">R<span className={styles.hideMobile} aria-hidden="true">oun</span>d</div>}
      <div className={`${styles.labelValue} ${styles.hideMobile}`} role="columnheader" aria-label="round 1 score">R1</div>
      {rounds >= 2 && <div className={`${styles.labelValue} ${styles.hideMobile}`} role="columnheader" aria-label="round 2 score">R2</div>}
      {rounds >= 3 && <div className={`${styles.labelValue} ${styles.hideMobile}`} role="columnheader" aria-label="round 3 score">R3</div>}
      {rounds >= 4 && <div className={`${styles.labelValue} ${styles.hideMobile}`} role="columnheader" aria-label="round 4 score">R4</div>}
      <div className={`${styles.labelValue} ${styles.hideMobile}`} role="columnheader" aria-label="strokes">Strokes</div>
    </Box>
  )
}

LeaderboardHead.propTypes = {
  rounds: PropTypes.number,
  showFavorite: PropTypes.bool,
  nested: PropTypes.bool,
  hideThru: PropTypes.bool,
}

LeaderboardHead.defaultProps = {}

export default LeaderboardHead
