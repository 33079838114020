import React from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import CtaArrow, { CtaText } from '@components/atoms/cta-arrow'

export const MeetCoachBtn = ({ styles, nativeAdCta }) => {
  return (
    <Button
      variant="outlined"
      color="secondary"
      size="small"
      className={styles.actionButton}
    >
      {nativeAdCta || 'Meet My Coach'}
    </Button>
  )
}

MeetCoachBtn.propTypes = {
  styles: PropTypes.object,
  nativeAdCta: PropTypes.string,
}

export const ArrowCta = ({ nativeAdCta }) => (
  <CtaText
    style={{ padding: '0 15px' }}
  >
    {nativeAdCta || 'Meet Your Coach'}
    <CtaArrow color="black" />
  </CtaText>
)

ArrowCta.propTypes = {
  nativeAdCta: PropTypes.string,
}
