import React from 'react'
import PropTypes from 'prop-types'
import Hidden from '@material-ui/core/Hidden'
import useStyles from './rolex-clock.styles'

const RolexClock = (props) => {
  const {
    isMobile,
    showExternalAd,
  } = props
  const styles = useStyles()

  if (!showExternalAd) return null

  return isMobile ? (
    <Hidden smUp implementation="css" className={styles.mobileAd}>
      <iframe
        title="rolex-clock"
        src="https://static.rolex.com/clocks/2021/PGAcom_mobile_HTML_300x70/rolex.html"
        style={{
          width: '90vw', height: '70px', border: 0, margin: 0, padding: 0, overflow: 'hidden', scroll: 'none', borderRadius: '8px',
        }}
        scrolling="NO"
        frameBorder="NO"
      />
    </Hidden>
  ) : (
    <Hidden xsDown implementation="css" className={styles.ad}>
      <iframe
        title="rolex-clock"
        src="https://static.rolex.com/clocks/2021/PGAcom_Desk_HTML_180x80/rolex.html"
        style={{
          width: '180px', height: '80px', border: 0, margin: 0, padding: 0, overflow: 'hidden', scroll: 'none', borderRadius: '8px',
        }}
        scrolling="NO"
        frameBorder="NO"
      />
    </Hidden>
  )
}

RolexClock.propTypes = {
  isMobile: PropTypes.bool,
  showExternalAd: PropTypes.bool,
}

export default RolexClock
