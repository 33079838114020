import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    display: 'inline-flex',
    flexDirection: 'column',
    borderRadius: '8px',
    marginBottom: theme.spacing(1),
  },
  image: {
    width: '100%',
    height: '5rem',
    objectFit: 'cover',
    display: 'flex',
    justifyContent: 'center',
  },
  imageContainer: {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    position: 'relative',
    '&::after': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      height: '100%',
      width: '100%',
      backgroundColor: '#00234B',
      opacity: 0.75,
    },
  },
  headlineOverlay: {
    position: 'absolute',
    top: '40%',
    left: '50%',
    width: '80%',
    transform: 'translate(-50%, -50%)',
    color: theme.palette.gold.main,
    fontSize: '2em',
    textAlign: 'center',
    zIndex: 1,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  roundImage: {
    display: 'flex',
    width: '18px',
    height: '18px',
    borderRadius: '50%',
    border: `2px solid ${theme.palette.divider}`,
    overflow: 'hidden',
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(-0.25),
    marginBottom: theme.spacing(0.5),
    marginTop: theme.spacing(0),
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: `2.75rem ${theme.spacing(3)}px 1.75rem`,
    height: '200px',
    position: 'relative',
  },
  headline: {
    color: theme.palette.secondary.main,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    textAlign: 'center',
  },
  headlineWrapper: {
    marginLeft: '2.5rem',
    marginRight: '2.5rem',
    width: '100%',
  },
  logo: {
    zIndex: 1,
    height: '3.75rem',
    width: '3.75rem',
    flexShrink: 0,
    left: '44%',
    backgroundSize: '70%',
  },
  field: {
    display: 'flex',
    alignItems: 'center',
    paddingRight: theme.spacing(2),
    '&>svg': {
      viewBox: '8 0 24 24',
    },
  },
  priceField: {
    display: 'flex',
    alignItems: 'center',
    paddingRight: theme.spacing(2),
    '&>svg': {
      viewBox: '12 0 24 24',
      marginRight: theme.spacing(1),
    },
  },
  fieldText: {
    ...theme.typography.caption,
    color: theme.palette.grey.dark,
  },
  ctaContainer: {
    marginTop: 'auto',
    height: '50px',
    bottom: '0',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  dateRange: {
    textAlign: 'center',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(3),
    '& span': {
      ...theme.typography.caption,
      color: theme.palette.grey.dark,
    },
  },
  ctaButton: {
    width: '100%',
    height: '50px',
    padding: '0',
    borderTop: `1px solid ${theme.palette.grey.light}`,
    '&:hover': {
      color: theme.palette.offwhite.main,
      backgroundColor: theme.palette.secondary.light,
    },
    '&:focus': {
      color: theme.palette.offwhite.main,
      backgroundColor: theme.palette.secondary.light,
    },
  },
}))

export default useStyles
