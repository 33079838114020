import PropTypes from 'prop-types'
import { Box, Typography } from '@material-ui/core'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import { useStyles } from './scramble-leaderboard.style-hook'

const LeaderboardOuterItem = (props) => {
  const {
    matchName,
    isTournamentComplete,
    eventStage,
    winner,
    handleToggle,
    showLeaderboard,
  } = props

  const eventStages = {
    final: 'Championship Match',
    consolation: '3rd Place Match',
  }

  const styles = useStyles({ showLeaderboard })

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleToggle()
    }
  }
  return (
    <Box
      className={styles.outerItem}
      role="row"
      onClick={handleToggle}
      onKeyDown={handleKeyDown}
      tabIndex={0}
    >
      <div>
        <div className={styles.chevronWrapper}>
          <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
            <Typography variant="h5">{matchName}</Typography>
            {eventStage && <Typography variant="caption" style={{ fontWeight: 400 }}>{eventStages[eventStage]}</Typography>}
          </div>
          <KeyboardArrowDownIcon className={`${styles.chevron} ${showLeaderboard ? 'expanded' : ''}`} />
        </div>
        <div className={styles.statusWrapper}>
          {isTournamentComplete && <Typography className={styles.winnerTag}>{`Winner: ${winner}`}</Typography>}
          <div
            className={`${styles.progressTag} ${isTournamentComplete && styles.completed}`}
          >
            {isTournamentComplete ? 'Complete' : 'In Progress'}
          </div>
        </div>
      </div>
    </Box>
  )
}

LeaderboardOuterItem.propTypes = {
  matchName: PropTypes.string,
  handleToggle: PropTypes.func,
  eventStage: PropTypes.string,
  winner: PropTypes.string,
  showLeaderboard: PropTypes.bool,
  isTournamentComplete: PropTypes.bool,
}

LeaderboardOuterItem.defaultProps = {}

export default LeaderboardOuterItem
