import PropTypes from 'prop-types'
import {
  ColBox,
  CellBox,
  ScoreCellBox,
  Decor,
  Bogey,
  DoubleBogey,
  Birdey,
  Eagle,
  Albatross,
} from './scorecard.styles'

export const translateScore = (par, score) => {
  const rel = parseInt(score, 10) - parseInt(par, 10)
  switch (true) {
  case score === '' || score === '0':
    return <Decor>-</Decor>
  case rel >= 2:
    return <DoubleBogey>{score}</DoubleBogey>
  case rel === 1:
    return <Bogey>{score}</Bogey>
  case rel === 0:
    return <Decor>{score}</Decor>
  case rel === -1:
    return <Birdey>{score}</Birdey>
  case rel === -2:
    return <Eagle>{score}</Eagle>
  case rel <= -3:
    return <Albatross>{score}</Albatross>
  default:
    return null
  }
}

const ScoreCell = ({
  round,
  hole,
  par,
  score,
  rRelativePar,
  enableStatus,
}) => (
  <ColBox key={`${round}-${hole}`}>
    <CellBox>{hole}</CellBox>
    <CellBox>{par}</CellBox>
    <ScoreCellBox>{translateScore(par, score)}</ScoreCellBox>
    {enableStatus && <ScoreCellBox>{score ? rRelativePar : '-'}</ScoreCellBox>}
  </ColBox>
)

ScoreCell.propTypes = {
  hole: PropTypes.number,
  par: PropTypes.number,
  round: PropTypes.number,
  score: PropTypes.string,
  rRelativePar: PropTypes.string,
  enableStatus: PropTypes.bool,
}

export default ScoreCell
