import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { path } from 'ramda'
import { Image } from '@components/atoms'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Skeleton from '@material-ui/lab/Skeleton'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useTheme } from '@material-ui/core/styles'
import Link from 'next/link'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'
import secureLink from '@utils/secureLink'
import { formatNameForDisplay } from '@utils/textDisplay'
import useOnScreen from '@hooks/useOnScreen'
import { gtmEvent } from '@lib/gtm-utils'
import useStyles, { CoachName } from '../leaderboard.style-hook'
import { MeetCoachBtn } from './pieces'

const GetSkeletons = ({ isMobile, styles }) => (
  <Box className={styles.outer} display="flex" justifyContent="space-around" alignItems="center" style={{ height: '88px' }}>
    {isMobile ? (
      <>
        <Skeleton width={200} height={80} />
        <Skeleton width={130} height={60} />
      </>
    ) : (
      <>
        <Skeleton width={112} height={35} variant="rect" />
        <Skeleton width={25} height={25} variant="circle" />
        <Skeleton width={300} height={35} variant="rect" />
        <Skeleton width={500} height={35} variant="rect" />
        <Skeleton width={130} height={50} variant="rect" />
      </>
    )}
  </Box>
)

GetSkeletons.propTypes = {
  isMobile: PropTypes.bool,
  styles: PropTypes.object,
}

const defaultTitle = 'PGA Coach Near You'

const getCoachCityStateText = (coach) => {
  const city = coach.city || path(['facilities', '0', 'city'], coach)
  const state = coach.state || path(['facilities', '0', 'state'], coach)
  if (city && state) {
    return `Your coach in ${city}, ${state}`
  }
  return defaultTitle
}

const CoachAdvertisment = ({
  coach, nativeAdDescription, nativeAdCta, leaderboardEvent,
}) => {
  const styles = useStyles()
  const theme = useTheme()
  const isTablet = useMediaQuery(theme.breakpoints.down('sm'))
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'))

  const logo = '/images/logo-blue.png'

  const bannerAdRef = useRef(null)
  const isCardVisible = useOnScreen(bannerAdRef)
  const [bannerHasBeenViewed, setBannerHasBeenViewed] = useState(false)

  const coachName = coach?.firstName && coach?.lastName ? `${coach.firstName} ${coach.lastName}` : coach?.displayName

  useEffect(() => {
    if (isCardVisible && !bannerHasBeenViewed) {
      gtmEvent({
        event: 'leaderboard-coach-ad-impression',
        attributes: {
          coach_name: coachName,
          coach_profile_id: coach?.objectID,
          title: leaderboardEvent?.eventName,
          tour_name: leaderboardEvent?.tourName,
        },
      })
      // Remove the event below in the future.
      gtmEvent({
        event: 'leaderboard_ad_impression',
        attributes: {
          bannerAdAction: 'impression',
          eventId: null,
          bannerAdType: 'coach',
          coachId: coach.objectID,
        },
      })
      setBannerHasBeenViewed(true)
    }
  }, [isCardVisible])

  const handleBannerClick = () => {
    gtmEvent({
      event: 'leaderboard-coach-ad-click',
      attributes: {
        coach_name: coachName,
        coach_profile_id: coach?.objectID,
        event_name: leaderboardEvent?.eventName,
        tour_name: leaderboardEvent?.tourName,
      },
    })
    // Remove the event below in the future.
    gtmEvent({
      event: 'leaderboard_ad_impression',
      attributes: {
        bannerAdAction: 'click',
        eventId: null,
        bannerAdType: 'coach',
        coachId: coach.objectID,
      },
    })
  }

  if (coach) {
    return (
      <div className={styles.outer} ref={bannerAdRef}>
        <Link href={`/coach/${coach.customSlug}`}>
          <Box onClick={() => handleBannerClick()}>
            <div className={styles.coachItem}>
              <div style={{ display: 'flex' }}>
                <Image src={logo} alt="PGA logo" className={styles.coachPgaLogo} />
                <div className={styles.info}>
                  {coach.profilePhoto ? (
                    <figure className={styles.roundImage}>
                      <img
                        src={secureLink(coach.profilePhoto)}
                        className={styles.coachAvatar}
                        alt="Coach Portrait"
                      />
                    </figure>
                  ) : null}
                  <CoachName coach={coach}>
                    <div className={styles.firstName}>{formatNameForDisplay(coach.firstName)}</div>
                    <Typography className={styles.lastName} variant="h6">{formatNameForDisplay(coach.lastName)}</Typography>
                  </CoachName>
                </div>
              </div>
              <Typography className={styles.caption} variant="caption">{getCoachCityStateText(coach)}</Typography>
              {!isTablet && (
                <div className={styles.nativeDescription}>
                  <Typography variant="subtitle2">{nativeAdDescription}</Typography>
                </div>
              )}
              {/* default if there is a cookie issue */}
              {isDesktop && <MeetCoachBtn styles={styles} nativeAdCta={nativeAdCta} />}
              {!isDesktop && <ArrowForwardIosIcon className={styles.carrotCta} />}
            </div>
          </Box>
        </Link>
      </div>
    )
  }
  return null
}


CoachAdvertisment.propTypes = {
  coach: PropTypes.object,
  nativeAdDescription: PropTypes.string,
  nativeAdCta: PropTypes.string,
}

export default CoachAdvertisment
