/* eslint-disable no-nested-ternary */
import React, {
  useEffect, useState, useContext,
} from 'react'
import PropTypes from 'prop-types'
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import NoSsr from '@material-ui/core/NoSsr'
import Typography from '@material-ui/core/Typography'
import ErrorBoundary from '@components/error/boundary'
import LeaderboardCutLine from '@components/leaderboard-cut-line'
import RolexClock from '@components/rolex-clock'
import ShowcaseEventBanner from '@components/showcase-event-banner'
import MobileShowcaseEventBanner from '@components/showcase-event-banner/mobile'
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import LeaderboardHead from './leaderboard-head'
import LeaderboardItem from './leaderboard-item'
import Disclaimer from './leaderboard-disclaimer'
import { LeaderboardContext, actionTypes } from './leaderboard.provider'
import useStyles, { PlayoffButton } from './leaderboard.style-hook'
import CoachAdvertisement from './CoachAdvertisement'
import { defineLpgaCutPlaces, getAdBannerData } from './utils'

const Leaderboard = React.memo((props) => {
  const {
    players,
    id,
    feedId,
    name: eventName,
    enableScorecard,
    bordered,
    currentRound,
    currentRoundState,
    totalRounds,
    rounds = 4,
    updatedAt,
    style,
    enableStatus,
    liveStreamUrl,
    nativeAdTitle,
    nativeAdDescription,
    nativeAdCta,
    nativeAdActive,
    teamOf20PlayerIds,
    showTeam20Cutline,
    cutLineText,
    teamOf20CutLineText,
    cutLineTextOverride,
    cutPlaces,
    cutPlacesOverride,
    cutRound,
    cutScore,
    showExternalAd,
    leaderboardPlaces,
    fullPage,
    hideThru,
  } = props

  const tournamentCurrentRound = currentRound || players[0]?.currentRound || players[0]?.rounds.length
  const tournamentRounds = totalRounds || rounds
  const isTournamentComplete = Number(tournamentCurrentRound) === tournamentRounds && currentRoundState === 'Completed'
  const playoffStarted = isTournamentComplete && players[0].currentPosition === 'T1'
  const playoffCompleted = isTournamentComplete && (players[0].tournamentStreak === players[1].tournamentStreak) && Number(players[0].currentPosition) === 1
  const showPlayoffButton = playoffStarted || playoffCompleted
  const playoffText = playoffCompleted ? 'Playoff: Complete' : 'Playoff: In Progress'

  const styles = useStyles()
  const isMobile = useMediaQuery(useTheme().breakpoints.down('xs'))
  const [nearestCoach, setNearestCoach] = useState(null)
  const [loadingData, setLoadingData] = useState(false)
  const [bannerAdData, setBannerAdData] = useState(null)

  useEffect(() => {
    const getAdBanner = async () => {
      setLoadingData(true)
      const bannerData = await getAdBannerData()
      if (bannerData) {
        setBannerAdData(bannerData.event)
        setNearestCoach(bannerData.coach)
      }
      setLoadingData(false)
    }
    if (nativeAdActive && leaderboardPlaces !== '3' && leaderboardPlaces !== '5') {
      getAdBanner()
    }
  }, [])

  const { state, dispatch } = useContext(LeaderboardContext)
  const { players: playersSaved } = state[id] || []

  let lpgaCutPlaces

  if (feedId === 'LPGA') {
    lpgaCutPlaces = defineLpgaCutPlaces(players, cutScore, cutRound, currentRound)
  }

  const leaderboardCutPlaces = cutPlacesOverride || lpgaCutPlaces || cutPlaces

  const showPlacesDict = {
    3: 3,
    5: 5,
    10: 10,
    20: 20,
    'Cut only': leaderboardCutPlaces,
    'All players': null,
    null: 5, // default places if no leaderboardPlaces value provided by Contentful
  }

  const defineTeam20Data = (playerId, team20Players) => {
    if (!team20Players || !team20Players[feedId] || !Array.isArray(team20Players[feedId])) return null
    let team20Data
    if (typeof team20Players[feedId][0] === 'object') {
      team20Data = team20Players[feedId].find(player => player.id === playerId)
    } else if (typeof team20Players[feedId][0] === 'string') {
      team20Data = team20Players[feedId].includes(playerId)
    }
    return team20Data
  }

  const limitValue = showPlacesDict[leaderboardPlaces]
  const showFavorite = enableScorecard && !limitValue
  const filteredPlayers = (players || []).filter(player => player != null && player.player != null && player.sortIndex != null)
  const noSortIndexPlayers = (players || []).filter(player => player != null && player.player != null)
  const defaultPlayersToOrder = !filteredPlayers.length ? noSortIndexPlayers : filteredPlayers
  const team20PlayersToOrder = []
  if (teamOf20PlayerIds && teamOf20PlayerIds[feedId]?.length > 0) {
    defaultPlayersToOrder.forEach((player) => {
      team20PlayersToOrder.push({
        ...player,
        isTeamOf20: defineTeam20Data(player.player.playerId, teamOf20PlayerIds),
      })
    })
  }
  const playersToOrder = (teamOf20PlayerIds && team20PlayersToOrder?.length > 0) ? team20PlayersToOrder : defaultPlayersToOrder
  let orderedPlayers = playersToOrder.sort((a, b) => a.sortIndex - b.sortIndex)
  if (teamOf20PlayerIds && teamOf20PlayerIds[feedId]?.length > 0) {
    orderedPlayers = orderedPlayers.sort((a, b) => {
      if (a.currentPosition === b.currentPosition) {
        return b.isTeamOf20 - a.isTeamOf20
      }
      return a.sortIndex - b.sortIndex
    })
  }
  const show = limitValue ? orderedPlayers.slice(0, limitValue) : orderedPlayers

  const getTeam20CutLine = () => {
    let res = 20
    if (show[19]?.currentPosition === show[20]?.currentPosition) {
      for (let i = 20; i < show.length; i += 1) {
        if (show[i].currentPosition !== show[i - 1].currentPosition) {
          res = i
          break
        }
      }
    }
    return res
  }

  const team20CutLine = getTeam20CutLine()

  const addPlayerFav = (player) => {
    dispatch({ type: actionTypes.ADD_PLAYER, payload: { tournamentId: id, player } })
  }

  const removePlayerFav = (player) => {
    dispatch({ type: actionTypes.REMOVE_PLAYER, payload: { tournamentId: id, player } })
  }

  const paddingY = [3, 2, 1]

  const showcaseCardHandler = () => {
    return bannerAdData ? (
      isMobile ? (
        <MobileShowcaseEventBanner
          data={bannerAdData}
          isLoading={loadingData}
        />
      ) : (
        <ShowcaseEventBanner
          data={bannerAdData}
          isLoading={loadingData}
        />
      )
    ) : null
  }

  const coachCardHandler = () => {
    return nearestCoach ? (
      <CoachAdvertisement
        isLoading={loadingData}
        coach={nearestCoach}
        nativeAdTitle={nativeAdTitle}
        nativeAdDescription={nativeAdDescription}
        nativeAdCta={nativeAdCta}
        leaderboardEvent={{
          eventName,
          tourName: feedId
        }}
      />
    ) : null
  }

  return (
    <Box py={paddingY} style={style}>
      {fullPage && <RolexClock showExternalAd={showExternalAd} isMobile />}
      <Container className={styles.wrapper} role="table">
        <Box display="flex" alignItems="flex-end" pt={[showExternalAd ? 2 : 0, 0, 0]}>
          <Box pt={1}>
            {!limitValue && <Typography variant="subtitle2" className={styles.descriptionTitle}>Full Leaderboard</Typography>}
            {showPlayoffButton && <PlayoffButton complete={{ playoff: playoffCompleted }}>{playoffText}</PlayoffButton>}
            <Disclaimer updatedAt={updatedAt} />
          </Box>
          {fullPage && <RolexClock showExternalAd={showExternalAd} />}
        </Box>
        <LeaderboardHead rounds={Number(tournamentCurrentRound)} showFavorite={showFavorite} hideThru={hideThru} />
        {show.map((player, i) => (
          <ErrorBoundary key={player.player.playerId}>
            {i === 5 && nativeAdActive && (
              <NoSsr>
                {coachCardHandler()}
              </NoSsr>
            )}
            {i === 10 && nativeAdActive && (
              <NoSsr>
                {showcaseCardHandler()}
              </NoSsr>
            )}
            {i === team20CutLine && showTeam20Cutline ? (
              <LeaderboardCutLine
                cutScore={Number(player.tournamentRelativePar)}
                cutLineText={teamOf20CutLineText}
                team20CutLine
              />
            ) : null}
            {i === leaderboardCutPlaces && tournamentCurrentRound >= cutRound ? (
              <LeaderboardCutLine
                cutScore={cutScore}
                cutRound={cutRound}
                currentRound={tournamentCurrentRound}
                currentRoundState={currentRoundState}
                cutLineText={cutLineText}
                cutLineTextOverride={cutLineTextOverride}
              />
            ) : null}
            <LeaderboardItem
              bordered={bordered}
              {...player}
              tournamentId={id}
              feedId={feedId}
              enableScorecard={enableScorecard}
              enableStatus={enableStatus}
              rounds={player.rounds}
              liveStreamUrl={liveStreamUrl}
              addItem={addPlayerFav}
              removeItem={removePlayerFav}
              saved={playersSaved && playersSaved.filter(
                playerSaved => playerSaved.player.playerId === player.player.playerId,
              ).length > 0}
              team20Data={defineTeam20Data(player.player.playerId, teamOf20PlayerIds)}
              showFavorite={showFavorite}
              winner={playoffCompleted && i === 0}
              tournamentCurrentRound={Number(tournamentCurrentRound)}
              hideThru={hideThru}
              eventName={eventName}
            />
          </ErrorBoundary>
        ))}
      </Container>
    </Box>
  )
})

Leaderboard.propTypes = {
  bordered: PropTypes.bool,
  enableScorecard: PropTypes.bool,
  feedId: PropTypes.string,
  id: PropTypes.string,
  players: PropTypes.array,
  rounds: PropTypes.number,
  updatedAt: PropTypes.string,
  style: PropTypes.object,
  liveStreamUrl: PropTypes.string,
  enableStatus: PropTypes.bool,
  nativeAdTitle: PropTypes.string,
  nativeAdDescription: PropTypes.string,
  nativeAdCta: PropTypes.string,
  nativeAdActive: PropTypes.bool,
  teamOf20PlayerIds: PropTypes.object,
  currentRound: PropTypes.string,
  currentRoundState: PropTypes.string,
  cutPlaces: PropTypes.number,
  cutRound: PropTypes.number,
  cutScore: PropTypes.number,
  showExternalAd: PropTypes.bool,
  leaderboardPlaces: PropTypes.string,
  fullPage: PropTypes.bool,
  showTeam20Cutline: PropTypes.bool,
  totalRounds: PropTypes.number,
  cutLineText: PropTypes.string,
  teamOf20CutLineText: PropTypes.string,
  cutLineTextOverride: PropTypes.string,
  cutPlacesOverride: PropTypes.number,
  hideThru: PropTypes.bool,
}

Leaderboard.defaultProps = {
  players: [],
}

export default Leaderboard
